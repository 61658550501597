import React, { useContext, useEffect } from "react";
import { DataContext } from "../../Context/DataContext";
import { Grid, Pagination, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router";
import "./WebStories.scss";
import { formatDateDifference } from "../../Utils/CommonFunction";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { CONSTANT, SEO } from "../../Utils/Constant";
import { Helmet } from "react-helmet";
import SmallLoader from "../Common/Loader/SmallLoader";

function WebStoryFullView() {
  const {
    stories,
    setModalUrl,
    fetchStories,
    webStoryLoader,
    setWebStoryLoader,
    setWebStoryViaHome,
    webStoryPageCount,
    webStoryPage,
    setWebStoryPage,
  } = useContext(DataContext);
  const navigate = useNavigate();

  useEffect(() => {
    fetchStories(webStoryPage, 6);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [webStoryPage]);

  const handleChange = (event, value) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setWebStoryLoader(true);
    setWebStoryPage(value);
  };
  return (
    <>
      <Helmet>
        <title>{SEO.WEB_STORY_TITLE}</title>
        <meta name="description" content={SEO.WEB_STORY_DESCRIPTION} />
        <meta name="keywords" content={SEO.WEB_STORY_KEYWORDS} />

        <meta property="og:title" content={SEO.WEB_STORY_TITLE} />
        <meta property="og:description" content={SEO.WEB_STORY_DESCRIPTION} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={CONSTANT.WEATHER_WEB_LINK} />
        <meta property="og:site_name" content={CONSTANT.WEATHER_TIMES} />

        <meta name="twitter:title" content={SEO.WEB_STORY_TITLE} />
        <meta name="twitter:description" content={SEO.WEB_STORY_DESCRIPTION} />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>

      <Grid className="web-stories-all">
        <Grid>
          <Typography variant="h1" className="web-stories-title">
            Web Stories
          </Typography>
        </Grid>
        {webStoryLoader || stories?.length <= 0 ? (
          <Grid className="story-loader-component">
            <SmallLoader />
          </Grid>
        ) : (
          <>
            <Grid container className="card-main-div">
              {stories.map((story, i) => (
                <Grid
                  className="web-stories-cards web-cards-in-full-page animation-zoom-in"
                  sx={{
                    cursor: "pointer",
                    margin:
                      stories.length % 2 !== 0 && i === stories.length - 1
                        ? "6px"
                        : "6px auto",
                  }}
                  key={i}
                  onClick={() => {
                    setModalUrl(
                      `${process.env.REACT_APP_WP_ENDPOINT}web-stories/${story?.slug}/`
                    );
                    setWebStoryViaHome(false);
                    navigate(`/mausam-web-stories/${story?.slug}`);
                  }}
                >
                  <img
                    src={story?.thumbnail}
                    alt={story?.alt || "web-story-thumbnail"}
                    height={318}
                    width={250}
                    style={{ borderRadius: "8px" }}
                  />
                  <div className="web-stories-detail">
                    <p className="story-title">{story?.title || ""}</p>
                    <p className="story-time">
                      {formatDateDifference(
                        story?.time || "2024-06-17 03:22:26"
                      )}
                    </p>
                  </div>
                </Grid>
              ))}
            </Grid>
          </>
        )}
        <Grid
          item
          xs={12}
          sx={{ pt: 2 }}
          className="blog-category-pagination animation-zoom-in"
        >
          <Stack>
            <Pagination
              count={webStoryPageCount}
              page={webStoryPage}
              onChange={handleChange}
            />
          </Stack>
        </Grid>{" "}
      </Grid>
      <Grid className="previous-btn-div" onClick={() => navigate("/")}>
        <KeyboardBackspaceIcon />
        <Typography>Previous</Typography>
        <Grid />
      </Grid>
    </>
  );
}

export default WebStoryFullView;
