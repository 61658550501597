import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import "./BlogComponent.scss";
import BlogSideBarCard from "./BlogSideBarCard";
import axios from "axios";
function BlogComponent() {
  const [firstSideBarData, setFirstSideBarData] = useState([]);
  const [secondSideBarData, setSecondSideBarData] = useState([]);

  useEffect(() => {
    getAllInfiniteSliderPost("top-stories", setFirstSideBarData);

    getAllInfiniteSliderPost("featured-stories", setSecondSideBarData);
  }, []);

  const getAllInfiniteSliderPost = (category, setState) => {
    try {
      axios
        .get(
          `${process.env.REACT_APP_WP_ENDPOINT}wp-json/weather-api/v1/category-posts/${category}`
        )
        .then((res) => {
          setState(res?.data);
        })
        .catch((err) => {
          console.log(err);
          setState([]);
        });
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <Grid className="blog-sidebar-container">
      <BlogSideBarCard
        StaticBlogList={firstSideBarData}
        title="Top Stories"
        slug="top-stories"
      />
      <BlogSideBarCard
        StaticBlogList={secondSideBarData}
        title="Featured Stories"
        slug="featured-stories"
      />
    </Grid>
  );
}

export default BlogComponent;
