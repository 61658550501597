import React from "react";
import "./Loader.scss";
import { Grid } from "@mui/material";

function Loader() {
  return (
    <Grid className="loader-div">
      <Grid className="loader"></Grid>
      <Grid className="loader-p"></Grid>
    </Grid>
  );
}

export default Loader;
