import React, { useContext, useEffect, useState } from "react";
import "./MonthlyWeather.scss";
import { Button, Divider, Grid, Typography } from "@mui/material";
import HourlyLinkLabel from "../../Common/HourlyWeather/HourlyLinkLabel/HourlyLinkLabel";
import { CONSTANT, SEO } from "../../../Utils/Constant";
import { Helmet } from "react-helmet";
import WarningIcon from "@mui/icons-material/Warning";
import TrendingFlatIcon from "@mui/icons-material/TrendingFlat";
import SelectBoxCommon from "../../Common/MonthlyWeather/SelectBoxCommon";
import CalenderContent from "../../Common/MonthlyWeather/CalenderContent";
import {
  formatWeatherAlert,
  getFirstAndLastDateOfMonth,
  getMonthDates,
  getMonthNamesForYear,
  getYearsArray,
} from "../../../Utils/CommonFunction";
import moment from "moment";
import { useNavigate } from "react-router";
import axios from "axios";
import { DataContext } from "../../../Context/DataContext";
import Loader from "../../Common/Loader/Loader";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
function MonthlyWeather() {
  const {
    selectedLocation,
    browserAttachLocation,
    setTabbingValue,
    cityForecastWeather,
    isFahrenheitValue,
    setShowWeatherTabbing,
  } = useContext(DataContext);

  const navigate = useNavigate();

  const [selectedMonth, setSelectedMonth] = useState(
    moment(new Date()).format("MMMM")
  );

  const [selectedYear, setSelectedYear] = useState(
    moment(new Date()).format("YYYY")
  );

  const [isLoading, setIsLoading] = useState(false);
  const [isFilterClick, setIsFilterClick] = useState(false);
  const [isFilterDisable, setIsFilterDisable] = useState(true);
  const [monthlyStaticWeatherData, setMonthlyStaticWeatherData] = useState(
    getMonthDates(selectedMonth, selectedYear) || []
  );

  useEffect(() => {
    setTabbingValue(4);
    setShowWeatherTabbing(true);
    const dates = getFirstAndLastDateOfMonth(selectedMonth, selectedYear);
    setMonthlyStaticWeatherData(getMonthDates(selectedMonth, selectedYear));
    const getMonthlyWeatherData = async () => {
      setIsLoading(true);
      if (selectedLocation?.url !== undefined && selectedLocation?.url !== "") {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_WEATHER_ENDPOINT}history.json?q=${selectedLocation?.url}&dt=${dates?.firstDate}&end_dt=${dates?.endDate}&key=${process.env.REACT_APP_API_KEY}`
          );
          const forecast = response?.data?.forecast?.forecastday;
          if (forecast) {
            updateMonthlyWeatherData(forecast);
          } else {
            setIsLoading(false);
          }
        } catch (error) {
          setIsLoading(false);
          console.log(error);
        }
      } else if (
        browserAttachLocation?.location !== undefined &&
        browserAttachLocation?.location !== ""
      ) {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_WEATHER_ENDPOINT}history.json?q=${browserAttachLocation?.location}&dt=${dates?.firstDate}&end_dt=${dates?.endDate}&key=${process.env.REACT_APP_API_KEY}`
          );
          const forecast = response?.data?.forecast?.forecastday;
          if (forecast) {
            updateMonthlyWeatherData(forecast);
          } else {
            setIsLoading(false);
          }
        } catch (error) {
          setIsLoading(false);
          console.log(error);
        }
      }
    };
    getMonthlyWeatherData();
    setIsFilterDisable(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFilterClick, browserAttachLocation, selectedLocation]);

  const updateMonthlyWeatherData = (data) => {
    let tempArray = getMonthDates(selectedMonth, selectedYear);
    tempArray?.forEach((x, i) => {
      x?.forEach((y, index) => {
        const matchingItem = data?.find((item) => item?.date === y?.date);
        if (matchingItem) {
          tempArray[i][index] = matchingItem;
        }
      });
    });
    setMonthlyStaticWeatherData(tempArray);
    setIsLoading(false);
  };

  return (
    <>
      <Helmet>
        <title>{SEO.MONTHLY_WEATHER_TITLE}</title>
        <meta name="description" content={SEO.MONTHLY_WEATHER_DESCRIPTION} />
        <meta name="keywords" content={SEO.MONTHLY_WEATHER_KEYWORDS} />

        <meta property="og:title" content={SEO.MONTHLY_WEATHER_TITLE} />
        <meta
          property="og:description"
          content={SEO.MONTHLY_WEATHER_DESCRIPTION}
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={CONSTANT.WEATHER_WEB_LINK} />
        <meta property="og:site_name" content={CONSTANT.WEATHER_TIMES} />

        <meta name="twitter:title" content={SEO.MONTHLY_WEATHER_TITLE} />
        <meta
          name="twitter:description"
          content={SEO.MONTHLY_WEATHER_DESCRIPTION}
        />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>

      <Grid>
        <Grid
          className="monthly-page-warning-div"
          sx={{
            borderColor: cityForecastWeather?.alerts?.alert[0]
              ? "#d7c01f"
              : "#168d16",
            backgroundColor: cityForecastWeather?.alerts?.alert[0]
              ? "#f8f3d6"
              : "#bae6ba",
          }}
        >
          <Grid className="warning-div-content">
            <WarningIcon
              sx={{
                color: cityForecastWeather?.alerts?.alert[0]
                  ? "orange"
                  : "#168d16",
              }}
              className="warn-icon"
            />
            <Divider
              orientation="vertical"
              className="divider"
              variant="middle"
              flexItem
            />
            <Typography
              dangerouslySetInnerHTML={{
                __html:
                  cityForecastWeather?.alerts?.alert.length > 0
                    ? formatWeatherAlert(
                        cityForecastWeather?.alerts?.alert[0]?.desc,
                        cityForecastWeather?.alerts?.alert[0]?.effective
                      )
                    : "No any alert found now",
              }}
            ></Typography>
          </Grid>
        </Grid>
        <Grid className="hourly-weather-table-container">
          <Grid className="hourly-weather-table-heading">
            <Grid
              className="select-box-div"
              sx={{ gap: { xs: "10px !important" } }}
            >
              <SelectBoxCommon
                value={selectedMonth}
                setValue={setSelectedMonth}
                menuItem={getMonthNamesForYear(+selectedYear)}
                label="Month"
                setFlagState={setIsFilterDisable}
              />
              <SelectBoxCommon
                value={selectedYear}
                setValue={setSelectedYear}
                menuItem={getYearsArray("2010")}
                label="Year"
                setFlagState={setIsFilterDisable}
              />
              <Button
                variant="outlined"
                className="filter-btn-month"
                onClick={() => setIsFilterClick(!isFilterClick)}
                startIcon={<FilterAltOutlinedIcon />}
                disabled={isFilterDisable}
                sx={{
                  opacity: isFilterDisable ? 0.4 : 1,
                  cursor: isFilterDisable ? "not-allowed" : "pointer",
                }}
              >
                filter
              </Button>
            </Grid>
            <Grid
              className="select-box-div gap-5 day-navigate"
              onClick={() => navigate("/daily-mausam")}
            >
              <Typography>DAILY</Typography>
              <TrendingFlatIcon />
            </Grid>
          </Grid>

          {isLoading ? (
            <Grid sx={{ py: 8 }}>
              <Loader />
            </Grid>
          ) : (
            <>
              <Grid container className="hourly-table-content padding-y-8">
                <Typography items xs={1.7}>
                  S
                </Typography>
                <Typography items xs={1.7}>
                  M
                </Typography>
                <Typography items xs={1.7}>
                  T
                </Typography>
                <Typography items xs={1.7}>
                  W
                </Typography>
                <Typography items xs={1.7}>
                  T
                </Typography>
                <Typography items xs={1.7}>
                  F
                </Typography>
                <Typography items xs={1.7}>
                  S
                </Typography>
              </Grid>
              <Divider />
              <Grid container className="hourly-table-content">
                {monthlyStaticWeatherData?.length > 0 &&
                  monthlyStaticWeatherData?.map((array, index) => (
                    <>
                      {array?.length > 0 &&
                        array?.map((item, i) => (
                          <CalenderContent
                            date={item?.date}
                            temp1={
                              (isFahrenheitValue
                                ? item?.day?.maxtemp_f
                                : item?.day?.maxtemp_c) || item?.temp1
                            }
                            temp2={
                              (isFahrenheitValue
                                ? item?.day?.mintemp_f
                                : item?.day?.mintemp_c) || item?.temp2
                            }
                            imgIcon={item?.day?.condition?.icon}
                          />
                        ))}
                      <Grid items xs={12}>
                        <Divider />
                      </Grid>
                    </>
                  ))}
              </Grid>
            </>
          )}
        </Grid>
        <Grid sx={{ mb: 2 }}>
          <HourlyLinkLabel label="Further Ahead" />
          <HourlyLinkLabel
            label={moment().subtract(1, "months").format("MMMM YYYY")}
            isLink={true}
            isSameState={{
              month: moment().subtract(1, "months").format("MMMM"),
              year: new Date().getFullYear(),
            }}
            setMonth={setSelectedMonth}
            setYear={setSelectedYear}
            setFlagState={setIsFilterClick}
            flagState={isFilterClick}
          />
          <HourlyLinkLabel
            label={moment().subtract(2, "months").format("MMMM YYYY")}
            isLink={true}
            isSameState={{
              month: moment().subtract(2, "months").format("MMMM"),
              year: new Date().getFullYear(),
            }}
            setMonth={setSelectedMonth}
            setYear={setSelectedYear}
            setFlagState={setIsFilterClick}
            flagState={isFilterClick}
          />
          <HourlyLinkLabel
            label={moment().subtract(3, "months").format("MMMM YYYY")}
            isLink={true}
            isSameState={{
              month: moment().subtract(3, "months").format("MMMM"),
              year: new Date().getFullYear(),
            }}
            setMonth={setSelectedMonth}
            setYear={setSelectedYear}
            setFlagState={setIsFilterClick}
            flagState={isFilterClick}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default MonthlyWeather;
