import React, { useContext, useEffect } from "react";
import { DataContext } from "../../../Context/DataContext";
import { Helmet } from "react-helmet";
import { CONSTANT, SEO } from "../../../Utils/Constant";
import "./PrivacyPolicy.scss";
import "./ContactUs.scss";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import { Grid, Typography } from "@mui/material";
import dayjs from "dayjs";
function PrivacyPolicy() {
  const { setIsPrivacyPage, setShowWeatherTabbing } = useContext(DataContext);
  useEffect(() => {
    setIsPrivacyPage(true);
    setShowWeatherTabbing(false);
  });

  return (
    <>
      <Helmet>
        <title>{SEO.PRIVACY_POLICY_TITLE}</title>
        <meta name="description" content={SEO.PRIVACY_POLICY_DESCRIPTION} />
        <meta name="keywords" content={SEO.PRIVACY_POLICY_KEYWORDS} />

        <meta property="og:title" content={SEO.PRIVACY_POLICY_TITLE} />
        <meta
          property="og:description"
          content={SEO.PRIVACY_POLICY_DESCRIPTION}
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={CONSTANT.WEATHER_WEB_LINK} />
        <meta property="og:site_name" content={CONSTANT.WEATHER_TIMES} />

        <meta name="twitter:title" content={SEO.PRIVACY_POLICY_TITLE} />
        <meta
          name="twitter:description"
          content={SEO.PRIVACY_POLICY_DESCRIPTION}
        />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <Grid sx={{ my: 2 }}>
        <Grid>
          <Typography variant="h5" className="back-btn">
            <ArrowBackOutlinedIcon
              sx={{ cursor: "pointer" }}
              onClick={() => window.history.back()}
            />
            Privacy Policy
          </Typography>
        </Grid>
        <Grid className="privacy-policy">
          <Typography variant="h2">Introduction</Typography>
          <Typography>
            Welcome to Mausam, your go-to weather forecast app.{" "}
            <a href="/">
              <b>At Mausam</b>
            </a>
            , we are committed to protecting your privacy and ensuring that your
            personal information is handled in a safe and responsible manner.
            This Privacy Policy outlines the types of information we collect,
            how we use it, and the measures we take to safeguard your data.
          </Typography>
          <Typography variant="h2">Information We Collect</Typography>
          <Typography>
            We do not collect any personal information from visitors to our
            website. Our mausam app are designed to view without the need for
            user input or data collection.
          </Typography>
          <Typography variant="h2">Use of Cookies</Typography>
          <Typography>
            We use cookies to store search results of weather locations. Cookies
            are small files stored on your device that help us remember your
            search preferences and improve your experience on our website.
          </Typography>
          <Typography variant="h2">Third-Party Links</Typography>
          <Typography>
            Our website may contain links to third-party websites for additional
            resources or information. Please note that these websites have their
            own privacy policies, and we do not accept any responsibility or
            liability for their policies or practices.
          </Typography>

          <Typography variant="h2">Security of Your Information</Typography>
          <Typography>
            The security of your data is important to us. We employ a variety of
            security measures to protect your personal information from
            unauthorized access, use, or disclosure. However, no method of
            transmission over the Internet, or method of electronic storage, is
            100% secure. While we strive to use commercially acceptable means to
            protect your personal information, we cannot guarantee its absolute
            security.
          </Typography>
          <Typography variant="h2">Contact Us</Typography>
          <Typography>
            If you have any questions or concerns regarding our Privacy Policy,
            please contact us at{" "}
            <b>
              <u>{process.env.REACT_APP_CONTACT_EMAIL}</u>
            </b>
            .
          </Typography>
          <Typography>
            Effective Date:{" "}
            <b>{dayjs().startOf("month").format("DD MMMM,YYYY")}</b>
          </Typography>
        </Grid>
      </Grid>
    </>
  );
}

export default PrivacyPolicy;
