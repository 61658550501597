import axios from "axios";
import React, { createContext, useEffect, useState } from "react";
import {
  eraseCookie,
  getCookie,
  setCookie,
  weatherApiCheckForPrivacyRoute,
  weatherApiCheckOrRedirect,
} from "../Utils/CommonFunction";
import moment from "moment";
import { BulkWeatherCall } from "../Utils/Constant";

export const DataContext = createContext();

export const DataContextProvider = ({ children }) => {
  const [category, setCategory] = useState({});
  const [tabbingValue, setTabbingValue] = useState(0);
  const [linkClicked, setLinkClicked] = useState(false);
  const [dayIndex, setDayIndex] = useState(0);
  const [selectedLocation, setSelectedLocation] = useState({});
  const [cityForecastWeather, setCityForecastWeather] = useState();
  const [browserAttachLocation, setBrowserAttachLocation] = useState({});
  const [tenDaysForecast, setTenDaysForecast] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isTodayWeatherShow, setIsTodayWeatherShow] = useState(new Date());
  const [currentWeatherShow, setCurrentWeatherShow] = useState();
  const [isFahrenheitValue, setIsFahrenheitValue] = useState(false);
  const [isHomePage, setIsHomePage] = useState(true);
  const [recentData, setRecentData] = useState(getCookie("recentData") || []);
  const [showWeatherTabbing, setShowWeatherTabbing] = useState(false);
  const [doRedirect, setDoRedirect] = useState(false);
  const [isPrivacyPage, setIsPrivacyPage] = useState(false);
  const [modalUrl, setModalUrl] = useState(null);
  const [stories, setStories] = useState([]);
  const [webStoryViaHome, setWebStoryViaHome] = useState(true);
  const [webStoryLoader, setWebStoryLoader] = useState(true);
  const [webStoryPageCount, setWebStoryPageCount] = useState(1);
  const [webStoryPage, setWebStoryPage] = useState(1);

  const [categoryBlogViewRenderViaUrl, setCategoryBlogViewRenderViaUrl] =
    useState(true);
  const [bulkCityData, setBulkCityData] = useState([]);

  useEffect(() => {
    getTenDaysWeatherForecast();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLocation, browserAttachLocation]);

  const getTenDaysWeatherForecast = async () => {
    setIsLoading(true);
    if (selectedLocation?.url !== undefined && selectedLocation?.url !== "") {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_WEATHER_ENDPOINT}forecast.json?q=${selectedLocation?.url}&days=10&aqi=yes&alerts=yes&key=${process.env.REACT_APP_API_KEY}`
        );

        setDoRedirect(true);
        setTimeout(() => {
          setDoRedirect(false);
        }, 2000);
        setTenDaysForecast(response.data);
        setCityForecastWeather({
          current: response?.data?.current,
          location: response?.data?.location,
          forecast: {
            forecastday: [response?.data?.forecast?.forecastday[0]],
          },
          alerts: response?.data?.alerts,
        });

        const newEntry = {
          city: response?.data?.location?.name,
          temp_c: response?.data?.current?.temp_c,
          temp_f: response?.data?.current?.temp_f,
          temp_real_c: response?.data?.current?.feelslike_c,
          temp_real_f: response?.data?.current?.feelslike_f,
          url: selectedLocation?.url,
          country: response?.data?.location?.country,
          img: response?.data?.current?.condition?.icon,
        };

        const urlExists = recentData?.some(
          (entry) => entry.url === newEntry.url
        );

        if (!urlExists) {
          if (recentData?.length === 5) {
            recentData.shift();
          }
          recentData.push(newEntry);
        }
        if (getCookie("recentData") === null) {
          setCookie("recentData", JSON.stringify(recentData), 7);
          setRecentData(recentData);
        } else {
          eraseCookie("recentData");
          setCookie("recentData", JSON.stringify(recentData), 7);
          setRecentData(recentData);
        }

        setIsLoading(false);
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
    } else if (browserAttachLocation?.location !== undefined) {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_WEATHER_ENDPOINT}forecast.json?q=${browserAttachLocation?.location}&days=10&aqi=yes&alerts=yes&key=${process.env.REACT_APP_API_KEY}`
        );

        setTenDaysForecast(response.data);
        setCityForecastWeather({
          current: response?.data?.current,
          location: response?.data?.location,
          forecast: {
            forecastday: [response?.data?.forecast?.forecastday[0]],
          },
          alerts: response?.data?.alerts,
        });
        setIsLoading(false);
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
    }
  };

  const getSpecificDateForecast = async (date) => {
    let jsonAccess = "";
    const minDate = moment().clone().subtract(1, "days").format("YYYY-MM-DD");
    const maxDate = moment().clone().add(15, "days").format("YYYY-MM-DD");
    if (date < minDate) {
      jsonAccess = "history.json";
    } else if (maxDate < date) {
      jsonAccess = "future.json";
    } else {
      jsonAccess = "forecast.json";
    }

    if (selectedLocation?.url !== undefined && selectedLocation?.url !== "") {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_WEATHER_ENDPOINT}${jsonAccess}?q=${selectedLocation?.url}&days=1&dt=${date}&key=${process.env.REACT_APP_API_KEY}`
        );

        return response.data?.forecast?.forecastday;
      } catch (error) {
        console.log(error);
      }
    } else if (browserAttachLocation?.location !== undefined) {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_WEATHER_ENDPOINT}${jsonAccess}?q=${browserAttachLocation?.location}&days=1&dt=${date}&key=${process.env.REACT_APP_API_KEY}`
        );

        return response.data?.forecast?.forecastday;
      } catch (error) {
        console.log(error);
      }
    }
  };

  const fetchUserLocation = async () => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;

          fetch(
            `https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${latitude}&lon=${longitude}`
          )
            .then((response) => response.json())
            .then((data) => {
              setBrowserAttachLocation({
                location:
                  data?.address?.city && data?.address?.state
                    ? `${data?.address?.city},${data?.address?.state}`
                    : `${data?.lat},${data?.lon}`,
                postCode: data?.address?.postCode,
                latitude: data?.lat,
                longitude: data?.lon,
              });
            })
            .catch((error) => {
              console.error("Error getting location:", error);
            });
        },
        (error) => {
          console.error("Error getting location:", error);
          alert("Please, Allow location permission");
        }
      );
    }
  };
  useEffect(() => {
    if (weatherApiCheckForPrivacyRoute()) {
      setIsPrivacyPage(true);
      setShowWeatherTabbing(false);
    } else {
      setIsPrivacyPage(false);
    }
    if (window.location.pathname === "/") {
      setIsHomePage(true);
      setShowWeatherTabbing(false);
    } else if (window.location.pathname.match(/^\/mausam-news-detail\//)) {
      setIsHomePage(false);
      setShowWeatherTabbing(false);
    } else if (weatherApiCheckOrRedirect()) {
      setShowWeatherTabbing(true);
      setIsHomePage(false);
    } else {
      setIsHomePage(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname]);

  const getAllCategoryFromWpPost = (count) => {
    try {
      axios
        .get(
          `${process.env.REACT_APP_WP_ENDPOINT}wp-json/weather-api/v1/all_category_with_post/${count}`
        )
        .then((res) => {
          setCategory(res?.data);
        })
        .catch((err) => {
          console.log(err);
          setCategory([]);
        });
    } catch (e) {
      console.log(e);
    }
  };

  const fetchStories = async (page, per_page) => {
    setWebStoryLoader(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_WP_ENDPOINT}wp-json/weather-api/v1/web-stories/-1?page=${page}&per_page=${per_page}`
      );

      const webStoryData = response?.data?.map((item) => ({
        id: item?.id,
        slug: item?.slug,
        title: item?.title,
        thumbnail: JSON.parse(item.post_content_filtered).pages[0]?.elements[0]
          ?.resource?.src,
        alt: JSON.parse(item.post_content_filtered).pages[0]?.elements[0]
          ?.resource?.alt,
        time: item?.modified,
      }));
      setWebStoryPageCount(
        Math.floor(response?.data[0]?.total_record / 6) + 1 || 1
      );
      setStories(webStoryData);
      setWebStoryLoader(false);
    } catch (error) {
      setWebStoryLoader(false);
      console.error("Error fetching web stories:", error);
    }
  };

  const fetchCitiesBulkWeather = () => {
    try {
      axios
        .post(
          `${process.env.REACT_APP_WEATHER_ENDPOINT}current.json?q=bulk&key=${process.env.REACT_APP_API_KEY}`,
          BulkWeatherCall
        )
        .then((res) => {
          setBulkCityData(
            res?.data?.bulk?.map((item) => ({
              name: item.query.location.name,
              temp_c: item.query.current.temp_c,
              temp_f: item.query.current.temp_f,
              text: item.query.current.condition.text,
              icon: item.query.current.condition.icon,
            }))
          );
        })
        .catch((err) => {
          console.log(err);
          setBulkCityData([]);
        });
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <DataContext.Provider
      value={{
        tabbingValue,
        setTabbingValue,
        linkClicked,
        setLinkClicked,
        selectedLocation,
        setSelectedLocation,
        browserAttachLocation,
        setBrowserAttachLocation,
        fetchUserLocation,
        cityForecastWeather,
        tenDaysForecast,
        dayIndex,
        setDayIndex,
        isLoading,
        isTodayWeatherShow,
        setIsTodayWeatherShow,
        getSpecificDateForecast,
        currentWeatherShow,
        setCurrentWeatherShow,
        isFahrenheitValue,
        setIsFahrenheitValue,
        isHomePage,
        setIsHomePage,
        recentData,
        category,
        showWeatherTabbing,
        setShowWeatherTabbing,
        doRedirect,
        isPrivacyPage,
        setIsPrivacyPage,
        getAllCategoryFromWpPost,
        categoryBlogViewRenderViaUrl,
        setCategoryBlogViewRenderViaUrl,
        modalUrl,
        setModalUrl,
        stories,
        setStories,
        fetchStories,
        webStoryViaHome,
        setWebStoryViaHome,
        webStoryLoader,
        setWebStoryLoader,
        webStoryPageCount,
        setWebStoryPageCount,
        webStoryPage,
        setWebStoryPage,
        bulkCityData,
        setBulkCityData,
        fetchCitiesBulkWeather,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};
