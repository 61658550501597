import React, { useContext, useEffect, useState } from "react";
import { Grid } from "@mui/material";
import AccordionCommon from "../../Common/MinutecastCommonComponent/AccordionCommon";
import HourlyLinkLabel from "../../Common/HourlyWeather/HourlyLinkLabel/HourlyLinkLabel";
import { Helmet } from "react-helmet";
import { CONSTANT, SEO } from "../../../Utils/Constant";
import { DataContext } from "../../../Context/DataContext";
import { generateMinutesArray } from "../../../Utils/CommonFunction";
import Loader from "../../Common/Loader/Loader";

function MinutecastWeather() {
  const {
    setTabbingValue,
    cityForecastWeather,
    isFahrenheitValue,
    setShowWeatherTabbing,
  } = useContext(DataContext);

  const [minuteArray, setMinutesArray] = useState([]);

  useEffect(() => {
    setTabbingValue(3);
    setShowWeatherTabbing(true);
    if (cityForecastWeather?.forecast?.forecastday[0]?.hour?.length > 0) {
      setMinutesArray(
        generateMinutesArray(
          cityForecastWeather?.forecast?.forecastday[0]?.hour,
          isFahrenheitValue
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cityForecastWeather, isFahrenheitValue]);

  return (
    <>
      <Helmet>
        <title>{SEO.MINUTECAST_WEATHER_TITLE}</title>
        <meta name="description" content={SEO.MINUTECAST_WEATHER_DESCRIPTION} />
        <meta name="keywords" content={SEO.MINUTECAST_WEATHER_KEYWORDS} />
        <meta property="og:title" content={SEO.MINUTECAST_WEATHER_TITLE} />
        <meta
          property="og:description"
          content={SEO.MINUTECAST_WEATHER_DESCRIPTION}
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={CONSTANT.WEATHER_WEB_LINK} />
        <meta property="og:site_name" content={CONSTANT.WEATHER_TIMES} />
        <meta name="twitter:title" content={SEO.MINUTECAST_WEATHER_TITLE} />
        <meta
          name="twitter:description"
          content={SEO.MINUTECAST_WEATHER_DESCRIPTION}
        />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>

      <Grid>
        {minuteArray?.length > 0 ? (
          minuteArray.map((item, index) => (
            <AccordionCommon
              index={index + 1}
              title={item?.title}
              isFahrenheitValue={isFahrenheitValue}
              data={item?.data.map((value) => ({
                time: value?.time,
                status: value?.status,
                icon: value?.icon,
                temp: value?.temp,
              }))}
            />
          ))
        ) : (
          <Loader />
        )}

        <Grid sx={{ mb: 2 }}>
          <HourlyLinkLabel label="Further Ahead" />
          <HourlyLinkLabel label="Hourly" isLink={true} />
          <HourlyLinkLabel label="Daily" isLink={true} />
          <HourlyLinkLabel label="Monthly" isLink={true} />
        </Grid>
      </Grid>
    </>
  );
}

export default MinutecastWeather;
