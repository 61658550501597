import React from "react";
import "./DateWiseWeather.scss";
import { Divider, Grid, Typography } from "@mui/material";
import moment from "moment";
import ViewDetailsWithLabel from "../HourlyWeather/ViewDetailsWithLabel/ViewDetailsWithLabel";
import Loader from "../Loader/Loader";
import noWeatherIcon from "../../../Assets/Icon/no-weather.jpg";

function DateWiseWeatherCard(props) {
  const {
    timeStatus,
    date,
    icon,
    temp_c,
    realFill,
    chance_of_rain,
    labelValueArray,
    timeStatusDes,
    isLoader = false,
    isNotDataFound = false,
    isCurrentWeather = false,
  } = props;
  return (
    <Grid className="date-wise-weather-container">
      <Grid className="date-wise-weather-container-top">
        <Typography variant="h2">{timeStatus}</Typography>
        {isCurrentWeather ? (
          <Typography>{moment(date).format("h:m a")}</Typography>
        ) : isLoader ? (
          " "
        ) : (
          <Typography>{moment(date).format("M/D")}</Typography>
        )}
      </Grid>
      <Divider className="divider-black" />
      {isLoader ? (
        <Grid sx={{ p: 6 }}>
          <Loader />
        </Grid>
      ) : isNotDataFound ? (
        <Grid sx={{ p: 6, textAlign: "center" }}>
          <img src={noWeatherIcon} alt="no-weather found" height="300px" />
          <Typography className="no-data-found-text">
            Sorry, We can not find data for {moment(date).format("YYYY-MM-DD")}.
          </Typography>
        </Grid>
      ) : (
        <Grid className="date-wise-weather-container-bottom">
          <Grid container>
            <Grid item xs={12} sm={6} className="display-flex ">
              <img src={icon} alt={timeStatus} height="62px" />
              <Typography className="date-wise-temp">{temp_c}°</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography sx={{ my: 1 }}>
                RealFeel<sup> ®</sup> {realFill}°
              </Typography>
              <Typography>
                {isCurrentWeather
                  ? `Humidity ${chance_of_rain}%`
                  : `Rain 💧 ${chance_of_rain}%`}
              </Typography>
            </Grid>
            <Typography className="date-wise-description">
              {timeStatusDes}
            </Typography>
          </Grid>
          <Grid container sx={{ justifyContent: "space-between", mt: 2 }}>
            {labelValueArray.map((item, index) => {
              const isFirst =
                labelValueArray?.length % 2 === 0
                  ? index === labelValueArray?.length - 2 ||
                    index === labelValueArray?.length - 1
                  : index === labelValueArray?.length - 1;

              return (
                <ViewDetailsWithLabel
                  label={item?.label}
                  value={item?.value}
                  isFirst={isFirst}
                />
              );
            })}
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}

export default DateWiseWeatherCard;
