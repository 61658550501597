import React, { useContext } from "react";
import { DataContext } from "../../../Context/DataContext";
import { Helmet } from "react-helmet";
import { CONSTANT, SEO } from "../../../Utils/Constant";
import { Grid, Typography } from "@mui/material";
import HourlyWeatherCard from "../../Common/HourlyWeather/Card/HourlyWeatherCard";
import HourlyLinkLabel from "../../Common/HourlyWeather/HourlyLinkLabel/HourlyLinkLabel";
import {
  getAirQualityLevel,
  getNext7Days,
} from "../../../Utils/CommonFunction";
import moment from "moment";
import Loader from "../../Common/Loader/Loader";

function DailyWeather() {
  const {
    setTabbingValue,
    tenDaysForecast,
    isFahrenheitValue,
    setShowWeatherTabbing,
    isLoading,
  } = useContext(DataContext);
  setTabbingValue(2);
  setShowWeatherTabbing(true);

  return (
    <>
      <Helmet>
        <title>{SEO.DAILY_WEATHER_TITLE}</title>
        <meta name="description" content={SEO.DAILY_WEATHER_DESCRIPTION} />
        <meta name="keywords" content={SEO.DAILY_WEATHER_KEYWORDS} />

        <meta property="og:title" content={SEO.DAILY_WEATHER_TITLE} />
        <meta
          property="og:description"
          content={SEO.DAILY_WEATHER_DESCRIPTION}
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={CONSTANT.WEATHER_WEB_LINK} />
        <meta property="og:site_name" content={CONSTANT.WEATHER_TIMES} />

        <meta name="twitter:title" content={SEO.DAILY_WEATHER_TITLE} />
        <meta
          name="twitter:description"
          content={SEO.DAILY_WEATHER_DESCRIPTION}
        />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      {isLoading ? (
        <Loader />
      ) : (
        <Grid>
          <Typography>
            {moment(tenDaysForecast?.forecast?.forecastday[0]?.date).format(
              "MMM D"
            )}{" "}
            -{" "}
            {moment(
              tenDaysForecast?.forecast?.forecastday[
                tenDaysForecast?.forecast?.forecastday?.length - 1
              ]?.date
            ).format("MMM D")}
          </Typography>
          {tenDaysForecast?.forecast?.forecastday.map((item, index) => (
            <HourlyWeatherCard
              weatherStatus={item?.day?.condition?.text}
              hourInterval={item?.date}
              imgIcon={item?.day?.condition?.icon}
              titleTemp={
                isFahrenheitValue ? item?.day?.maxtemp_f : item?.day?.maxtemp_c
              }
              minTemp={
                isFahrenheitValue ? item?.day?.mintemp_f : item?.day?.mintemp_c
              }
              rainPossibility={item?.day?.daily_chance_of_rain}
              labelValueArray={[
                {
                  label: "Average Shade™",
                  value: isFahrenheitValue
                    ? item?.day?.avgtemp_f
                    : item?.day?.avgtemp_c || "36°",
                },
                {
                  label: "Air Quality",
                  value: getAirQualityLevel(item?.air_quality),
                },
                {
                  label: "Max. Wind",
                  value: `${item?.day?.maxwind_kph || 0}km/h`,
                },
                {
                  label: "Max UV Index",
                  value: `${item?.uv || 0} Moderate`,
                },

                {
                  label: "Humidity",
                  value: `${item?.day?.avghumidity}%` || "0%",
                },

                {
                  label: "Visibility",
                  value: `${item?.day?.avgvis_km} km` || "0 km",
                },
                {
                  label: "Chance for Snow ",
                  value: `${item?.day?.daily_chance_of_snow} %` || "0 %",
                },
                {
                  label: "Sunrise ",
                  value: `${item?.astro?.sunrise}`,
                },
                {
                  label: "Sunset ",
                  value: `${item?.astro?.sunset}`,
                },
              ]}
              flag="daily"
            />
          ))}

          <Grid sx={{ mb: 2 }}>
            <HourlyLinkLabel label="Further Ahead" />
            {getNext7Days().map((day, index) => (
              <HourlyLinkLabel
                label={day?.dayName}
                count={day?.count}
                isLink={true}
              />
            ))}
          </Grid>
        </Grid>
      )}
    </>
  );
}

export default DailyWeather;
