import React, { useContext, useEffect, useState } from "react";
import "./BlogPageView.scss";
import { DataContext } from "../../../Context/DataContext";
import {
  Grid,
  Pagination,
  Stack,
  Tab,
  Tabs,
  tabsClasses,
  Typography,
} from "@mui/material";
import {
  formatDateDifference,
  modifyBlogsArrayForView,
  updateYoastHeadUrls,
} from "../../../Utils/CommonFunction";
import { useNavigate, useParams } from "react-router";
import BlogCategoryLink from "../../Common/BlogRedirectLink/BlogCategoryLink";
import axios from "axios";
import SmallLoader from "../../Common/Loader/SmallLoader";
import { Helmet } from "react-helmet";
import placeholderImg from "../../../Assets/Icon/placeholder.png";

function BlogPageView() {
  const {
    setShowWeatherTabbing,
    setIsHomePage,
    categoryBlogViewRenderViaUrl,
    setCategoryBlogViewRenderViaUrl,
  } = useContext(DataContext);
  const navigate = useNavigate();
  const [weatherTabbingValue, setWeatherTabbingValue] = useState(0);
  const [categoryViseBlogData, setCategoryViseBlogData] = useState([]);
  const { cat_slug } = useParams();
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isPageLoading, setIsPageLoading] = useState(false);

  const handleChange = (event, value) => {
    setIsPageLoading(true);
    setPage(value);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
    setShowWeatherTabbing(false);
    if (categoryBlogViewRenderViaUrl && categoryViseBlogData?.length > 0) {
      const index =
        categoryViseBlogData?.length > 0 &&
        categoryViseBlogData.findIndex(
          (item) => item?.category_slug === cat_slug
        );
      setWeatherTabbingValue(index !== false ? index : 0);
      setCategoryBlogViewRenderViaUrl(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryViseBlogData, cat_slug]);

  useEffect(() => {
    getAllCategoryFromWpPost(page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [weatherTabbingValue, page]);

  const getAllCategoryFromWpPost = (count) => {
    try {
      axios
        .get(
          `${process.env.REACT_APP_WP_ENDPOINT}wp-json/weather-api/v1/all_category_with_post/-1?page=${page}&per_page=9`
        )
        .then((res) => {
          setCategoryViseBlogData(res?.data);
          setCount(
            Math.floor(res?.data[weatherTabbingValue]?.Total_Records / 9) || 0
          );
          setIsLoading(false);
          setIsPageLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setCategoryViseBlogData([]);
          setCount(0);
          setIsLoading(false);
          setIsPageLoading(false);
        });
    } catch (e) {
      console.log(e);
      setIsLoading(false);
      setIsPageLoading(false);
    }
  };
  const handleChangeWeatherTabbing = (event, newValue) => {
    setIsLoading(true);
    setPage(1);
    setWeatherTabbingValue(newValue);
  };

  const openSingleBlogViewHandler = (id) => {
    setShowWeatherTabbing(false);
    setIsHomePage(false);
    navigate(`/mausam-news-detail/${id}`);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const parser = new DOMParser();
  const doc = parser.parseFromString(
    categoryViseBlogData[weatherTabbingValue]?.yoast_head
      ? updateYoastHeadUrls(categoryViseBlogData[weatherTabbingValue]?.yoast_head)
      : "",
    "text/html"
  );
  const headElements = Array.from(doc.head.children);
  return (
    <>
      <Helmet>
        {headElements.map((element, index) => {
          // Clone the element to use as React component
          const attributes = Array.from(element.attributes).reduce(
            (acc, attr) => {
              acc[attr.name] = attr.value;
              return acc;
            },
            {}
          );

          // Check the tag name and create corresponding React component
          switch (element.tagName) {
            case "TITLE":
              return <title key={index}>{element.textContent}</title>;
            case "META":
              return <meta key={index} {...attributes} />;
            case "SCRIPT":
              return (
                <script key={index} {...attributes} type="application/ld+json">
                  {element.textContent}
                </script>
              );
            default:
              return null;
          }
        })}
      </Helmet>
      <Grid container>
        <Grid item xs={12} className="tabbing-parent-div">
          <Tabs
            value={weatherTabbingValue || 0}
            onChange={handleChangeWeatherTabbing}
            variant="scrollable"
            scrollButtons
            aria-label="visible arrows tabs example"
            sx={{
              [`& .${tabsClasses.scrollButtons}`]: {
                "&.Mui-disabled": { opacity: 0.3 },
              },
              width: "100%",
            }}
          >
            {categoryViseBlogData?.length > 0 &&
              categoryViseBlogData.map((label, index) => (
                <Tab label={label?.category_name} id={index} />
              ))}
          </Tabs>
        </Grid>
        {isLoading ? (
          <Grid className="loader-parent-div">
            <SmallLoader />
          </Grid>
        ) : (
          <>
            <Grid
              container
              sx={{ mt: 1, py: 2, px: 3, justifyContent: "space-between" }}
            >
              <Typography variant="h4">
                {categoryViseBlogData?.length > 0 &&
                  categoryViseBlogData[weatherTabbingValue]?.category_name}
              </Typography>
              {!isPageLoading && categoryViseBlogData?.length > 0 ? (
                modifyBlogsArrayForView(
                  categoryViseBlogData[weatherTabbingValue]?.posts
                )?.map((value, index) => {
                  if (index === 0) {
                    return value.map((item, i) => (
                      <Grid item xs={12} key={index}>
                        <Grid
                          className="blog-category-index-0-card animation-zoom-in"
                          onClick={(e) => openSingleBlogViewHandler(item?.slug)}
                        >
                          <img
                            src={
                              item?.thumbnail !== false
                                ? item?.thumbnail
                                : placeholderImg
                            }
                            alt="blog-poster"
                          />
                          <Grid className="blog-category-index-0-detail">
                            <Typography
                              className="title"
                              dangerouslySetInnerHTML={{
                                __html: item?.title,
                              }}
                            ></Typography>
                            <Typography className="time">
                              {formatDateDifference(item?.modified)}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    ));
                  }
                  if (index === 1) {
                    return value.map((item, i) => (
                      <Grid
                        item
                        xs={12}
                        sm={5.9}
                        className="blog-category-index-1-2-card animation-zoom-in"
                        key={index}
                        onClick={(e) => openSingleBlogViewHandler(item?.slug)}
                      >
                        <Grid className="blog-data">
                          <Typography
                            className="blog-data-time"
                            dangerouslySetInnerHTML={{
                              __html: item?.title,
                            }}
                          ></Typography>
                          <Typography className="blog-data-post-time">
                            {formatDateDifference(item?.modified)}
                          </Typography>
                        </Grid>
                        <Grid className="blog-data-img">
                          <img
                            src={
                              item?.thumbnail !== false
                                ? item?.thumbnail
                                : placeholderImg
                            }
                            alt="blog-img"
                            height="64px"
                          />
                        </Grid>
                      </Grid>
                    ));
                  }

                  if (index === 2) {
                    return (
                      <>
                        <Grid item xs={12} key={index}>
                          <Grid
                            container
                            sx={{ justifyContent: "space-between", my: 2 }}
                          >
                            {value?.length > 0 &&
                              value?.map((item, i) => (
                                <Grid item xs={12} md={5.9}>
                                  {item?.length > 0 &&
                                    item?.map((innerItem, ii) =>
                                      i % 2 === 0 ? (
                                        <Grid
                                          className="blog-view-index-3-4 animation-zoom-in"
                                          sx={{ my: 2 }}
                                          onClick={(e) =>
                                            openSingleBlogViewHandler(
                                              innerItem?.slug
                                            )
                                          }
                                        >
                                          <img
                                            src={
                                              innerItem?.thumbnail !== false
                                                ? innerItem?.thumbnail
                                                : placeholderImg
                                            }
                                            alt="blog-img"
                                          />
                                          <Grid className="blog-data" key={ii}>
                                            <Typography
                                              className="blog-data-time"
                                              dangerouslySetInnerHTML={{
                                                __html: innerItem?.title,
                                              }}
                                            ></Typography>
                                            <Typography className="blog-data-post-time">
                                              {formatDateDifference(
                                                innerItem?.modified
                                              )}
                                            </Typography>
                                          </Grid>
                                        </Grid>
                                      ) : (
                                        <Grid
                                          className="blog-category-index-5-8-card animation-zoom-in"
                                          sx={{ mt: 2 }}
                                          onClick={(e) =>
                                            openSingleBlogViewHandler(
                                              innerItem?.slug
                                            )
                                          }
                                        >
                                          <Grid className="blog-data">
                                            <Typography
                                              className="blog-data-time"
                                              dangerouslySetInnerHTML={{
                                                __html: innerItem?.title,
                                              }}
                                            ></Typography>
                                            <Typography className="blog-data-post-time">
                                              {formatDateDifference(
                                                innerItem?.modified
                                              )}
                                            </Typography>
                                          </Grid>
                                          <Grid className="blog-data-img">
                                            <img
                                              src={
                                                innerItem?.thumbnail !== false
                                                  ? innerItem?.thumbnail
                                                  : placeholderImg
                                              }
                                              alt="blog-img"
                                              height="64px"
                                            />
                                          </Grid>
                                        </Grid>
                                      )
                                    )}
                                </Grid>
                              ))}
                          </Grid>
                        </Grid>
                      </>
                    );
                  }
                  if (index === 3) {
                    return value.map((item, i) => (
                      <Grid item xs={12} key={index}>
                        <Grid
                          className="blog-category-index-0-card animation-zoom-in"
                          onClick={(e) => openSingleBlogViewHandler(item?.slug)}
                        >
                          <img
                            src={
                              item?.thumbnail !== false
                                ? item?.thumbnail
                                : placeholderImg
                            }
                            alt="blog-poster"
                          />
                          <Grid className="blog-category-index-0-detail">
                            <Typography
                              className="title"
                              dangerouslySetInnerHTML={{
                                __html: item?.title,
                              }}
                            ></Typography>
                            <Typography className="time">
                              {formatDateDifference(item?.modified)}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    ));
                  } else {
                    return null;
                  }
                })
              ) : (
                <Grid className="loader-parent-div">
                  <SmallLoader />
                </Grid>
              )}
            </Grid>
            {count >= 1 && (
              <Grid
                item
                xs={12}
                className="blog-category-pagination animation-zoom-in"
              >
                <Stack>
                  <Pagination
                    count={count + 1}
                    page={page}
                    onChange={handleChange}
                  />
                </Stack>
              </Grid>
            )}
          </>
        )}
        <Grid item xs={12} sx={{ mt: 4, mb: 2, px: 2 }}>
          <BlogCategoryLink linkData={categoryViseBlogData} />
        </Grid>
      </Grid>
    </>
  );
}

export default BlogPageView;
