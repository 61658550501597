export const SEO = {
  HOME_PAGE_DESCRIPTION: "This is page for get weather for today",
  HOME_PAGE_KEYWORDS: "Mausam",
  HOME_PAGE_TITLE: "Mausam",

  TODAY_WEATHER_DESCRIPTION: "This is page for get weather for today",
  TODAY_WEATHER_KEYWORDS: "Today Weather",
  TODAY_WEATHER_TITLE: "Today Weather",

  HOURLY_WEATHER_DESCRIPTION: "This is page for get weather for hourly Mausam",
  HOURLY_WEATHER_KEYWORDS: "Hourly Mausam",
  HOURLY_WEATHER_TITLE: "Hourly Mausam",

  DAILY_WEATHER_DESCRIPTION: "This is page for get weather for daily Mausam",
  DAILY_WEATHER_KEYWORDS: "Daily Mausam",
  DAILY_WEATHER_TITLE: "Daily Mausam",

  MINUTECAST_WEATHER_DESCRIPTION:
    "This is page for get weather for minute cast Mausam",
  MINUTECAST_WEATHER_KEYWORDS: "Minute cast Mausam",
  MINUTECAST_WEATHER_TITLE: "Minute cast Mausam",

  MONTHLY_WEATHER_DESCRIPTION:
    "This is page for get weather for monthly cast Mausam",
  MONTHLY_WEATHER_KEYWORDS: "Monthly cast Mausam",
  MONTHLY_WEATHER_TITLE: "Monthly cast Mausam",

  SINGLE_BLOG_DESCRIPTION: "This page is show news article of Mausam",
  SINGLE_BLOG_KEYWORDS: "Monthly cast Mausam",
  SINGLE_BLOG_TITLE: "New Article",

  CATEGORY_PAGE_DESCRIPTION: "This page is show news articles all stories",
  CATEGORY_PAGE_KEYWORDS: "Monthly cast Mausam",
  CATEGORY_PAGE_TITLE: "Category of News Articles",

  DATEWISE_PAGE_DESCRIPTION: "This page is show Mausam for selected date",
  DATEWISE_PAGE_KEYWORDS: "Monthly cast Mausam",
  DATEWISE_PAGE_TITLE: "Mausam for Specific date",

  ERROR_DESCRIPTION: "Sorry, the page you are looking for does not exist.",
  ERROR_KEYWORDS: "404 error, page not found, website error",
  ERROR_TITLE: "404 Error - Page Not Found",

  PRIVACY_POLICY_DESCRIPTION:
    "Learn how we handle your data and privacy when using our Mausam services.",
  PRIVACY_POLICY_KEYWORDS: "privacy policy, data protection, Mausam ",
  PRIVACY_POLICY_TITLE: "Privacy Policy - Mausam Service",

  TERMS_SERVICE_DESCRIPTION:
    "Read the terms and conditions for using our Mausam services.",
  TERMS_SERVICE_KEYWORDS: "terms of service, user agreement, Mausam terms",
  TERMS_SERVICE_TITLE: "Terms of Service - Mausam Service",

  ABOUT_US_DESCRIPTION:
    "Discover more about our Mausam service and our mission.",
  ABOUT_US_KEYWORDS: "about us, Mausam, our mission",
  ABOUT_US_TITLE: "About Us - Mausam Service",

  DISCLAIMER_DESCRIPTION: "Disclaimer regarding the use of our service",
  DISCLAIMER_KEYWORDS:
    "Disclaimer, Liability, Ownership, Accuracy, Endorsement",
  DISCLAIMER_TITLE: "Disclaimer",

  WEB_STORY_DESCRIPTION:
    "Discover engaging web stories about our weather forecast services.",
  WEB_STORY_KEYWORDS:
    "weather forecast, web stories, service updates, weather accuracy, service liability, content ownership",
  WEB_STORY_TITLE: "Web Stories - Mausam",

  SINGLE_WEB_STORY_DESCRIPTION:
    "Explore individual web stories featuring our weather forecast services.",
  SINGLE_WEB_STORY_KEYWORDS:
    "weather forecast, web stories, service updates, weather accuracy, service liability, content ownership",
  SINGLE_WEB_STORY_TITLE: "Web Story - Mausam",
};

export const CONSTANT = {
  WEATHER_WEB_LINK: "https://mausam.net/",
  WEATHER_TIMES: "Mausam",
  commonSunIcon: "http://cdn.weatherapi.com/weather/128x128/day/113.png",
};

export const KeywordArrayHindi = [
  {
    title: "मौसम",
    path: "/today-mausam",
  },
  {
    title: "कल का मौसम",
    path: `/mausam-specified-date`,
  },
  {
    title: "आज का मौसम",
    path: "/today-mausam",
  },
  {
    title: "अगले 10 दिनों का मौसम",
    path: "/monthly-mausam",
  },
  {
    title: "10 दिनों का मौसम",
    path: "/daily-mausam",
  },
  {
    title: "मौसम कल",
    path: "/mausam-specified-date",
  },
  {
    title: "का मौसम",
    path: "/today-mausam",
  },
  {
    title: "आज शाम का मौसम",
    path: "/mausam-specified-date",
  },
  {
    title: "अगले 15 दिन का मौसम",
    path: "/monthly-mausam",
  },
  {
    title: "मौसम की जानकारी",
    path: "/today-mausam",
  },
  {
    title: "मेरे स्थान पर आज का मौसम",
    path: "/today-mausam",
  },
  {
    title: "आज का मौसम कैसा रहेगा",
    path: "/today-mausam",
  },
  {
    title: "कल का मौसम कैसा रहेगा पानी का",
    path: "/today-mausam",
  },
  {
    title: "अगले 10 दिन का मौसम का हाल",
    path: "/daily-mausam",
  },
  {
    title: "10 दिन का मौसम का हाल",
    path: "/daily-mausam",
  },
  {
    title: "14 दिनों का मौसम",
    path: "/monthly-mausam",
  },
  {
    title: "अगले 10 दिनों में बारिश का मौसम",
    path: "/daily-mausam",
  },
  {
    title: "कल का बारिश का मौसम",
    path: "/mausam-specified-date",
  },
  {
    title: "आज मौसम क्या है",
    path: "/today-mausam",
  },
  {
    title: "30 दिन का मौसम",
    path: "/monthly-mausam",
  },
];

export const KeywordArrayEnglish = [
  {
    title: "aaj ka mausam",
    path: "/today-mausam",
  },
  {
    title: "mausam ki jankari",
    path: "/monthly-mausam",
  },
  {
    title: "aaj ka mausam kaisa rahega",
    path: "/today-mausam",
  },
  {
    title: "mausam ka hal",
    path: "/monthly-mausam",
  },
  {
    title: "kal ka mausam",
    path: "/mausam-specified-date",
  },
  {
    title: "aaj ka mausam kaisa hai",
    path: "/today-mausam",
  },
  {
    title: "mausam vibhag",
    path: "/monthly-mausam",
  },
  {
    title: "aaj mausam kaisa rahega",
    path: "/today-mausam",
  },
  {
    title: "cal ka mausam kaisa rahega",
    path: "/mausam-specified-date",
  },
  {
    title: "aaj ka mausam ka hal",
    path: "/today-mausam",
  },
  {
    title: "10 din ka mausam",
    path: "/daily-mausam",
  },
  {
    title: "aaj ke mausam ka hal",
    path: "/today-mausam",
  },
  {
    title: "aaj ke mausam ki jankari",
    path: "/today-mausam",
  },
  {
    title: "barish ka mausam",
    path: "/today-mausam",
  },
  {
    title: "kal ka mausam kaisa rahega",
    path: "/mausam-specified-date",
  },
  {
    title: "today mausam",
    path: "/today-mausam",
  },
];

export const cityArray = [
  {
    name: "Ahmedabad",
  },
  {
    name: "Bengaluru",
  },
  {
    name: "Bhopal",
  },
  {
    name: "Chandigarh",
  },
  {
    name: "Chennai",
  },
  {
    name: "Delhi",
  },
  {
    name: "Dispur",
  },
  {
    name: "Faridabad",
  },
  {
    name: "Guwahati",
  },
  {
    name: "Jaipur",
  },
  {
    name: "Kolkata",
  },
  {
    name: "Lucknow",
  },
  {
    name: "Ludhiana",
  },
  {
    name: "Mumbai",
  },
  {
    name: "New Delhi",
  },
  {
    name: "Patna",
  },
  {
    name: "Pune",
  },
  {
    name: "Raipur",
  },
  {
    name: "Ranchi",
  },
  {
    name: "Surat",
  },
];

export const BulkWeatherCall = {
  locations: [
    {
      q: "Ahmedabad",
      custom_id: "Ahmedabad",
    },
    {
      q: "Bengaluru",
      custom_id: "Bengaluru",
    },
    {
      q: "Bhopal",
      custom_id: "Bhopal",
    },
    {
      q: "Chandigarh",
      custom_id: "Chandigarh",
    },
    {
      q: "Chennai",
      custom_id: "Chennai",
    },
    {
      q: "Delhi",
      custom_id: "Delhi",
    },
    {
      q: "Dispur",
      custom_id: "Dispur",
    },
    {
      q: "Faridabad",
      custom_id: "Faridabad",
    },
    {
      q: "Guwahati",
      custom_id: "Guwahati",
    },
    {
      q: "Jaipur",
      custom_id: "Jaipur",
    },
    {
      q: "Kolkata",
      custom_id: "Kolkata",
    },
    {
      q: "Lucknow",
      custom_id: "Lucknow",
    },
    {
      q: "Ludhiana",
      custom_id: "Ludhiana",
    },
    {
      q: "Mumbai",
      custom_id: "Mumbai",
    },
    {
      q: "Delhi",
      custom_id: "Delhi",
    },
    {
      q: "Patna",
      custom_id: "Patna",
    },
    {
      q: "Pune",
      custom_id: "Pune",
    },
    {
      q: "Raipur",
      custom_id: "Raipur",
    },
    {
      q: "Ranchi",
      custom_id: "Ranchi",
    },
    {
      q: "Surat",
      custom_id: "Surat",
    },
  ],
};
