import React, { useContext } from "react";
import "./404.scss";
import { Grid, Typography } from "@mui/material";
import notFound from "../../../Assets/Icon/404.png";
import { Home } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { DataContext } from "../../../Context/DataContext";
import { CONSTANT, SEO } from "../../../Utils/Constant";
import { Helmet } from "react-helmet";
function Error() {
  const navigate = useNavigate();
  const { setIsHomePage } = useContext(DataContext);
  const goToHomeHandler = () => {
    navigate("/");
    setIsHomePage(true);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <>
      <Helmet>
        <title>{SEO.ERROR_TITLE}</title>
        <meta name="description" content={SEO.ERROR_DESCRIPTION} />
        <meta name="keywords" content={SEO.ERROR_KEYWORDS} />

        <meta property="og:title" content={SEO.ERROR_TITLE} />
        <meta property="og:description" content={SEO.ERROR_DESCRIPTION} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={CONSTANT.WEATHER_WEB_LINK} />
        <meta property="og:site_name" content={CONSTANT.WEATHER_TIMES} />

        <meta name="twitter:title" content={SEO.ERROR_TITLE} />
        <meta name="twitter:description" content={SEO.ERROR_DESCRIPTION} />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <Grid className="err-page-container">
        <img src={notFound} alt="page-not-found" />
        <Typography variant="h4">Oops, page not found!</Typography>
        <button onClick={() => goToHomeHandler()}>
          Go to Home <Home />
        </button>
      </Grid>
    </>
  );
}

export default Error;
