import { Grid, Typography } from "@mui/material";
import React from "react";
import "./BlogHomeCommonCard.scss";
import { formatDateDifference } from "../../../Utils/CommonFunction";
import placeholderImg from "../../../Assets/Icon/placeholder.png"
function BlogCard(props) {
  const { poster, title, time, openSingleBlogViewHandler, Id } = props;

  return (
    <Grid
      className="blog-card-container animation-zoom-in"
      onClick={(e) => openSingleBlogViewHandler(Id)}
    >
      <img src={poster || placeholderImg} alt="blog-poster" />
      <Typography
        className="title"
        dangerouslySetInnerHTML={{ __html: title }}
      />
      <Typography className="time">
        {formatDateDifference(time) || "21 hours ago"}
      </Typography>
    </Grid>
  );
}

export default BlogCard;
