import React from "react";
import "./AccordionCommon.scss";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { Grid, Typography } from "@mui/material";
function AccordionCommon(props) {
  const { index, title, data, isFahrenheitValue } = props;
  return (
    <Grid sx={{ margin: "6px 0" }}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`panel${index}-content`}
          id={`panel${index}-header`}
          className="accordion-title"
        >
          <Grid className="accordion-title-div">
            <Typography>{title}</Typography>
            <img src={data[0]?.icon} alt={data[0]?.status} height="44px" />
            <Typography sx={{ fontWeight: 600, fontSize: "20px" }}>
              {data[0]?.temp}
              {isFahrenheitValue ? `°F` : `°C`}
            </Typography>
          </Grid>
        </AccordionSummary>
        <AccordionDetails sx={{ borderTop: "1px solid #ccc" }}>
          <Grid>
            {data.map((item, i) => (
              <Grid
                className="accordion-row"
                key={i}
                sx={{
                  borderBottom:
                    data?.length - 1 !== i
                      ? "1px solid #ccc"
                      : "1px solid transparent",
                }}
              >
                <Typography className="accordion-row-time">
                  {item?.time}
                </Typography>
                <img src={item?.icon} alt={item?.status} height="36px" />
                <Typography className="accordion-row-status">
                  {item?.status}
                </Typography>
                <Typography
                  sx={{ fontWeight: 600, fontSize: "16px !important" }}
                >
                  {item?.temp}
                  {isFahrenheitValue ? `°F` : `°C`}
                </Typography>
              </Grid>
            ))}
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
}

export default AccordionCommon;
