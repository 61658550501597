import React, { useContext, useEffect, useRef } from "react";
import "./BlogHomeCommonCard.scss";
import { Grid, Typography } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import BlogCard from "./blogCard";
import { DataContext } from "../../../Context/DataContext";
import { useNavigate } from "react-router";
import SmallLoader from "../../Common/Loader/SmallLoader";
import placeholderImg from "../../../Assets/Icon/placeholder.png";

function BlogHomeCommonCard() {
  const {
    category,
    setIsHomePage,
    setShowWeatherTabbing,
    getAllCategoryFromWpPost,
    setCategoryBlogViewRenderViaUrl,
    setBrowserAttachLocation,
    setSelectedLocation,
    bulkCityData,
    fetchCitiesBulkWeather,
    isFahrenheitValue,
  } = useContext(DataContext);
  const navigate = useNavigate();
  const mainDivRef = useRef(null);
  const arrowRef = useRef(null);

  const seeMoreClickHandler = (category) => {
    setIsHomePage(false);
    setCategoryBlogViewRenderViaUrl(true);
    navigate(`/mausam-news/${category}`);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const openSingleBlogViewHandler = (id) => {
    setShowWeatherTabbing(false);
    setIsHomePage(false);
    navigate(`/mausam-news-detail/${id}`);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    getAllCategoryFromWpPost(3);
    fetchCitiesBulkWeather();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const cityClickHandler = (path) => {
    setIsHomePage(false);
    setShowWeatherTabbing(true);
    setBrowserAttachLocation({ location: path });
    setSelectedLocation({ url: path });
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    navigate("/today-mausam");
  };

  const seeBottomArrowClick = () => {
    if (mainDivRef.current && arrowRef.current) {
      mainDivRef.current.style.maxHeight = mainDivRef.current.style.maxHeight === "fit-content" ? '216px' : "fit-content";
      arrowRef.current.style.transform = arrowRef.current.style.transform === "rotate(-90deg)" ? 'rotate(90deg)' : "rotate(-90deg)";
    }
  };

  return (
    <Grid container className="blog-home-common-card">
      <Grid item xs={12} className="d-flex-align-center space-btn">
        <Typography variant="h2">India Weather Conditions</Typography>
        <Typography
          className="d-flex-align-center gap-6 cursor-pointer see-more-cities"
          onClick={(e) => seeBottomArrowClick()}
        >
          See more <ArrowForwardIcon className="see-bottom-arrow" ref={arrowRef}/>
        </Typography>
      </Grid>
      {bulkCityData.length > 0 ? (
        <Grid container className="city-card-container" ref={mainDivRef}>
          {bulkCityData?.map((item, index) => (
            <Grid
              key={index}
              className="city-name-card"
              onClick={() => cityClickHandler(item?.name)}
            >
              <Grid>
                <Typography>{item?.name}</Typography>
              </Grid>
              <Grid className="city-name-card-temp">
                <img src={item?.icon} alt={item?.text || "sun"} height="40px" />
                <Typography>
                  {isFahrenheitValue
                    ? `${item?.temp_f} °F`
                    : `${item?.temp_c} °C` || 0}
                </Typography>
              </Grid>
            </Grid>
          ))}
        </Grid>
      ) : (
        <Grid className="small-loader-component-div">
          <SmallLoader />
        </Grid>
      )}

      {category?.length > 0 ? (
        <>
          {" "}
          {category.map((item, index) => (
            <>
              <Grid
                item
                xs={12}
                className="d-flex-align-center space-btn"
                key={index}
              >
                <Typography variant="h2">
                  {item?.category_name || "Weather News"}
                </Typography>
                <Typography
                  className="d-flex-align-center gap-6 cursor-pointer"
                  onClick={(e) => seeMoreClickHandler(item?.category_slug)}
                >
                  See more <ArrowForwardIcon />
                </Typography>
              </Grid>
              <Grid container className="card-main-div">
                {item?.posts.map((value, i) => (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    lg={4}
                    spacing={3}
                    sx={{ cursor: "pointer" }}
                    key={i}
                  >
                    <BlogCard
                      poster={
                        value?.thumbnail !== false
                          ? value?.thumbnail
                          : placeholderImg
                      }
                      title={value?.title}
                      time={value?.modified}
                      openSingleBlogViewHandler={openSingleBlogViewHandler}
                      Id={value?.slug}
                    />
                  </Grid>
                ))}
              </Grid>
            </>
          ))}
        </>
      ) : (
        <Grid className="small-loader-component-div">
          <SmallLoader />
        </Grid>
      )}
    </Grid>
  );
}

export default BlogHomeCommonCard;
