import React, { useContext, useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Grid, Typography } from "@mui/material";
import "./BlogComponent.scss";
import { formatDateDifference } from "../../../Utils/CommonFunction";
import { DataContext } from "../../../Context/DataContext";
import { useNavigate } from "react-router";
import axios from "axios";
import SmallLoader from "../Loader/SmallLoader";
import placeholderImg from "../../../Assets/Icon/placeholder.png";

function BottomInfiniteSlider() {
  const [trendingBlogs, setTrendingBlogs] = useState([]);
  const { setShowWeatherTabbing, setIsHomePage } = useContext(DataContext);
  const navigate = useNavigate();

  var settings = {
    dots: true,
    speed: 3000,
    slidesToShow: trendingBlogs?.length > 3 ? 3 : trendingBlogs?.length,
    slidesToScroll: trendingBlogs?.length > 3 ? 3 : trendingBlogs?.length,
    initialSlide: 0,
    // infinite: trendingBlogs.length > 3 ? true : false,
    // autoplay: trendingBlogs.length > 3 ? true : false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: trendingBlogs?.length > 2 ? 2 : trendingBlogs?.length,
          slidesToScroll: trendingBlogs?.length > 2 ? 2 : trendingBlogs?.length,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,

        settings: {
          slidesToShow: trendingBlogs?.length > 2 ? 2 : trendingBlogs?.length,
          slidesToScroll: trendingBlogs?.length > 2 ? 2 : trendingBlogs?.length,
          initialSlide: 2,
          dots: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: trendingBlogs?.length > 1 ? 1 : trendingBlogs?.length,
          slidesToScroll: trendingBlogs?.length > 1 ? 1 : trendingBlogs?.length,
          dots: false,
        },
      },
    ],
  };
  useEffect(() => {
    getAllInfiniteSliderPost("trending-stories");
  }, []);

  const getAllInfiniteSliderPost = async (category) => {
    try {
      axios
        .get(
          `${process.env.REACT_APP_WP_ENDPOINT}wp-json/weather-api/v1/category-posts/${category}`
        )
        .then((res) => {
          setTrendingBlogs(res?.data);
        })
        .catch((err) => {
          console.log(err);
          setTrendingBlogs([]);
        });
    } catch (e) {
      console.log(e);
    }
  };

  const openSingleBlogViewHandler = (id) => {
    setShowWeatherTabbing(false);
    setIsHomePage(false);
    navigate(`/mausam-news-detail/${id}`);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <div className="slider-container animation-zoom-in">
      <h2>Trending Today</h2>
      {trendingBlogs?.length > 0 ? (
        <>
          <Slider {...settings}>
            {trendingBlogs?.map((value, i) => (
              <Grid
                key={i}
                className="slider-blog-date-card"
                onClick={() => openSingleBlogViewHandler(value?.slug)}
              >
                <Grid sx={{ mb: 2 }}>
                  <img
                    src={
                      value?.thumbnail !== false
                        ? value?.thumbnail
                        : placeholderImg
                    }
                    alt="blog-img"
                    className="slider-blog-img"
                  />
                </Grid>
                <Grid className="slider-blog-detail">
                  <Typography
                    className="category"
                    dangerouslySetInnerHTML={{ __html: value?.title }}
                  ></Typography>
                  <Typography className="time">
                    {formatDateDifference(value?.modified)}
                  </Typography>
                </Grid>
              </Grid>
            ))}
          </Slider>
        </>
      ) : (
        <Grid className="small-loader-component-div">
          <SmallLoader />
        </Grid>
      )}
    </div>
  );
}

export default BottomInfiniteSlider;
