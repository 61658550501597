import React, { useEffect, useState } from "react";
import "./FooterPageComponent.scss";
import axios from "axios";
import SmallLoader from "../../Common/Loader/SmallLoader";
import { Grid } from "@mui/material";

function FooterPageComponent() {
  const [pageData, setPageData] = useState({});
  const [count, setCount] = useState(0);

  useEffect(() => {
    if (Object.keys(pageData).length === 0 && count < 3) {
      getPageData();
      setCount(count + 1);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageData]);

  const getPageData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_WP_ENDPOINT}wp-json/wp/v2/pages?slug=${process.env.REACT_APP_FOOTER_PAGE_SLUG}`
      );
      if (response) {
        setPageData(response?.data[0]);
      }
    } catch (error) {
      console.log(error);
      setPageData({});
    }
  };

  return (
    <>
      <div className="footer-dynamic-div">
        {Object.keys(pageData).length !== 0 ? (
          <>
            <div
              dangerouslySetInnerHTML={{ __html: pageData?.content?.rendered }}
            />{" "}
          </>
        ) : (
          <Grid className="small-loader-component-div">
            <SmallLoader />
          </Grid>
        )}
      </div>
    </>
  );
}

export default FooterPageComponent;
