import React, { useContext, useEffect, useState } from "react";
import { DataContext } from "../../../Context/DataContext";
import { Helmet } from "react-helmet";
import { CONSTANT, SEO } from "../../../Utils/Constant";
import "./ContactUs.scss";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import { Button, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router";
import emailjs from "@emailjs/browser";
import InputComponentContactUs from "../../Common/ContactUsCommon/InputComponentContactUs";

function ContactUs() {
  const { setIsPrivacyPage, setShowWeatherTabbing } = useContext(DataContext);
  useEffect(() => {
    setIsPrivacyPage(true);
    setShowWeatherTabbing(false);
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [stateMessage, setStateMessage] = useState(null);
  const [name, setName] = useState(null);
  const [email, setEmail] = useState(null);
  const [message, setMessage] = useState(null);
  const navigate = useNavigate();
  const sendEmail = (e) => {
    e.persist();
    e.preventDefault();
    setIsSubmitting(true);
    emailjs
      .send(
        process.env.REACT_APP_SERVICE_ID,
        process.env.REACT_APP_TEMPLATE_ID,
        {
          userName: name,
          userEmail: email,
          message: message,
          reply_to: email,
        },
        {
          publicKey: process.env.REACT_APP_PUBLIC_KEY,
        }
      )
      .then(
        (result) => {
          setStateMessage("Message sent!");
          setIsSubmitting(false);
          setTimeout(() => {
            setStateMessage(null);
            navigate("/");
          }, 4000);
        },
        (error) => {
          setStateMessage("Something went wrong, please try again later");
          setIsSubmitting(false);
          setTimeout(() => {
            setStateMessage(null);
            setName(null);
            setEmail(null);
            setMessage(null);
          }, 4000);
        }
      );

    e.target.reset();
  };
  return (
    <>
      <Helmet>
        <title>{SEO.ABOUT_US_TITLE}</title>
        <meta name="description" content={SEO.ABOUT_US_DESCRIPTION} />
        <meta name="keywords" content={SEO.ABOUT_US_KEYWORDS} />

        <meta property="og:title" content={SEO.ABOUT_US_TITLE} />
        <meta property="og:description" content={SEO.ABOUT_US_DESCRIPTION} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={CONSTANT.WEATHER_WEB_LINK} />
        <meta property="og:site_name" content={CONSTANT.WEATHER_TIMES} />

        <meta name="twitter:title" content={SEO.ABOUT_US_TITLE} />
        <meta name="twitter:description" content={SEO.ABOUT_US_DESCRIPTION} />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <Grid sx={{ px: 2, py: 1 }}>
        <Grid>
          <Typography variant="h5" className="back-btn">
            <ArrowBackOutlinedIcon
              sx={{ cursor: "pointer" }}
              onClick={() => window.history.back()}
            />
            Contact Us
          </Typography>
        </Grid>
        {stateMessage === null ? (
          <Grid className="contact-us-component common-animation">
            <form onSubmit={sendEmail}>
              <Grid
                className="input-detail-div"
                sx={{
                  width: { xs: "100%", md: "70%" },
                  m: { xs: "10px 0", md: "10px 0 10px 35px" },
                }}
              >
                <InputComponentContactUs
                  label="Name"
                  value={name}
                  setState={setName}
                />
                <InputComponentContactUs
                  label="Email"
                  value={email}
                  setState={setEmail}
                />
                <InputComponentContactUs
                  label="Message"
                  value={message}
                  setState={setMessage}
                  isTextArea={true}
                />
                <Grid sx={{ textAlign: "center" }}>
                  <Button
                    variant="contained"
                    type="submit"
                    color="success"
                    disabled={isSubmitting}
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Grid>
        ) : (
          <Grid className="contact-us-component common-animation">
            <Grid className="input-detail-div">{stateMessage}</Grid>
          </Grid>
        )}
      </Grid>
    </>
  );
}

export default ContactUs;
