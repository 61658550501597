import React, { useEffect, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import { Container, Grid, Tooltip, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import headerLogo from "../../../Assets/Icon/mainLogoMausam.png";
import "./Header.scss";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Autocomplete from "@mui/material/Autocomplete";
import axios from "axios";
import {
  extractOptionNumber,
  getCookie, weatherApiCheckOrRedirect
} from "../../../Utils/CommonFunction";
import { useContext } from "react";
import { DataContext } from "../../../Context/DataContext";
import { CONSTANT, KeywordArrayHindi } from "../../../Utils/Constant";
import cloudImg from "../../../Assets/Icon/cloudy.png";
import SearchIcon from "@mui/icons-material/Search";
import NotListedLocationIcon from "@mui/icons-material/NotListedLocation";
import SmallLoader from "../../Common/Loader/SmallLoader";
import HeaderBackground from "../../../Assets/Icon/commonBg.png";
function Header() {
  const {
    setSelectedLocation,
    cityForecastWeather,
    isFahrenheitValue,
    isHomePage,
    setIsHomePage,
    setShowWeatherTabbing,
    setBrowserAttachLocation,
    fetchUserLocation,
    doRedirect,
    isLoading,
  } = useContext(DataContext);

  const [searchData, setSearchData] = useState([]);
  const navigate = useNavigate();

  const returnHomeHandler = () => {
    navigate("/");
    setIsHomePage(true);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleSearch = async (e) => {
    try {
      if (e.target.value !== "") {
        const response = await axios.get(
          `${process.env.REACT_APP_WEATHER_ENDPOINT}search.json?q=${e.target.value}&key=${process.env.REACT_APP_API_KEY}`
        );
        setSearchData(response.data);
      } else {
        setSelectedLocation({});
      }
    } catch (error) {
      console.log(error);
    }
  };

 

  function onWindowScroll() {
    const scrollY = window.scrollY;
    let ele = document?.getElementById("header-home");
    if (scrollY > 100) {
      if (ele?.classList) {
        ele.classList.remove("header-class-home");
        ele.classList.add("header-class");
      }
    } else {
      if (ele?.classList) {
        ele.classList.remove("header-class");
        ele.classList.add("header-class-home");
      }
    }
  }
  useEffect(() => {
    window.addEventListener("scroll", onWindowScroll);
  }, []);

  const showWeatherHandler = (url) => {
    setIsHomePage(false);
    setShowWeatherTabbing(true);
    setBrowserAttachLocation({ location: url });
    setSelectedLocation({ url: url });
    navigate("/today-mausam");
  };

  const searchEventHandler = (e) => {
    if (e.target.getAttribute("aria-activedescendant")) {
      setSelectedLocation(
        searchData[
          extractOptionNumber(e.target.getAttribute("aria-activedescendant"))
        ]
      );
    } else {
      setSelectedLocation(searchData[extractOptionNumber(e.currentTarget?.id)]);
    }
    if (e.target.value !== "") {
      setShowWeatherTabbing(true);
      setIsHomePage(false);
      navigate("/today-mausam");
    }
  };
  const fetchWeatherViaLocation = () => {
    setSelectedLocation({ url: undefined });
    fetchUserLocation();
    setShowWeatherTabbing(true);
    setIsHomePage(false);
    navigate("/today-mausam");
  };

  const OpenWeatherByCityNameFromHeader = () => {
    setIsHomePage(false);
    setShowWeatherTabbing(true);
    navigate("/today-mausam");
  };
  const onBlurHandlerOfSearch = () => {
    const isRightPage = weatherApiCheckOrRedirect();
    if (!isRightPage) {
      setShowWeatherTabbing(true);
      setIsHomePage(false);
      navigate("/today-mausam");
    }
  };

  useEffect(() => {
    if (doRedirect) {
      onBlurHandlerOfSearch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [doRedirect]);

  return (
    <>
      {isHomePage ? (
        <>
          <Box className="header-box">
            <AppBar
              position="static"
              className="header-class-home"
              id="header-home"
            >
              <Container className="container-class">
                <Toolbar className="header-mainbar">
                  <img
                    src={headerLogo}
                    alt="logo"
                    className="header-logo-web-home"
                    onClick={() => returnHomeHandler()}
                  />
                </Toolbar>
              </Container>
            </AppBar>
          </Box>{" "}
          <Grid
            className="app-header-homepage"
            sx={{
              backgroundImage: `url(
                ${HeaderBackground})`,
            }}
          >
            <Grid className="max-w-50">
              <Grid className="search-header-homepage">
                <Stack className="search-hide-hover search-stack-div">
                  <Autocomplete
                    freeSolo
                    id="free-solo-2-demo"
                    disableClearable
                    options={searchData.map((option) => {
                      return `${option.name},${option.region}`;
                    })}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Search the city name to see the weather..."
                        InputProps={{
                          ...params.InputProps,
                          type: "search",
                        }}
                        onChange={(e) => handleSearch(e)}
                      />
                    )}
                    onChange={(e) => {
                      searchEventHandler(e);
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        searchEventHandler(e);
                      }
                    }}
                  />
                  <SearchIcon
                    className="header-search-icon"
                    sx={{ display: { xs: "none", sm: "block" } }}
                  />
                </Stack>
                <Tooltip
                  title="find via you current location"
                  placement="top"
                  arrow
                >
                  <NotListedLocationIcon
                    className="header-location-btn"
                    onClick={(e) => fetchWeatherViaLocation()}
                  />
                </Tooltip>
              </Grid>
              {getCookie("recentData")?.length > 0 ? (
                <>
                  <Typography className="recent-location-title">
                    Recent Locations
                  </Typography>
                  <Grid className="recent-search-location-list">
                    {getCookie("recentData")
                      .reverse()
                      .map((item, index) => (
                        <Grid
                          className={
                            
                             `recent-location-container animation-zoom-in recent-card-${index+1}`                              
                          }
                          onClick={(e) => showWeatherHandler(item?.url)}
                        >
                          <Typography className="city">
                            {item?.city || "Surat"}
                          </Typography>
                          <Typography className="country">
                            {item?.country || "India"}
                          </Typography>
                          <Typography
                            sx={{ display: "flex", alignItems: "center" }}
                          >
                            <img
                              src={item?.img || cloudImg}
                              alt="cloud-img"
                              height="32px"
                            />{" "}
                            <span className="temp">{`${
                              isFahrenheitValue
                                ? item?.temp_f
                                : item?.temp_c || "33"
                            }°`}</span>
                            <span className="temp-c">
                              {isFahrenheitValue ? "F" : "C"}
                            </span>
                          </Typography>
                          <Typography className="card-recent-typo">
                            RealFeel{" "}
                            <span>
                              {isFahrenheitValue
                                ? `${item?.temp_real_f || "96"}°F`
                                : `${item?.temp_real_c || "40"}°C`}
                            </span>
                          </Typography>
                        </Grid>
                      ))}
                  </Grid>
                </>
              ) : (
                ""
              )}
            </Grid>
          </Grid>
          <Grid
            className={
              getCookie("recentData") === null
                ? "keywords-div-hindi top-keywords mt-minus-130"
                : "keywords-div-hindi top-keywords"
            }
          >
            {KeywordArrayHindi.map((item, index) => (
              <Grid
                className="keyword-card"
                onClick={() => {
                  window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                  });
                  navigate(item?.path);
                }}
              >
                {item?.title}
              </Grid>
            ))}
          </Grid>
        </>
      ) : (
        <Box className="header-box">
          <AppBar position="static" className="header-class">
            <Container className="container-class">
              <Toolbar className="header-mainbar">
                <img
                  src={headerLogo}
                  alt="logo"
                  className="header-logo-web"
                  onClick={() => returnHomeHandler()}
                />
                {/* <img
                  src={mobileLogo}
                  alt="logo"
                  className="header-logo-mobile"
                  onClick={() => returnHomeHandler()}
                /> */}
                {isLoading ? (
                  <SmallLoader flag={true} />
                ) : (
                  <Typography
                    className="header-city-name"
                    onClick={() => OpenWeatherByCityNameFromHeader()}
                  >
                    <Tooltip
                      title={
                        cityForecastWeather?.location?.name
                          ? `${cityForecastWeather?.location?.name},${cityForecastWeather?.location?.region}`
                          : "Surat,Gujrat"
                      }
                      placement="top"
                      arrow
                    >
                      <span className="city-name-header-main">
                        {cityForecastWeather?.location?.name
                          ? `${cityForecastWeather?.location?.name},${cityForecastWeather?.location?.region}`
                          : "Surat,Gujrat"}
                      </span>
                    </Tooltip>
                    <span>
                      {isFahrenheitValue && cityForecastWeather?.current
                        ? `${cityForecastWeather?.current?.temp_f || 0}°F`
                        : !isFahrenheitValue &&
                          cityForecastWeather?.current &&
                          `${cityForecastWeather?.current?.temp_c || 0}°C`}
                    </span>
                    <img
                      src={
                        cityForecastWeather?.current?.condition?.icon ||
                        CONSTANT.commonSunIcon
                      }
                      alt={
                        cityForecastWeather?.current?.condition?.text || "sun"
                      }
                      height="40px"
                    />
                  </Typography>
                )}
                <Stack className="search-stack-div web-screen-search">
                  <Autocomplete
                    freeSolo
                    id="free-solo-2-demo"
                    disableClearable
                    options={searchData.map((option) => {
                      return `${option.name},${option.region}`;
                    })}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Search the city name to see the weather..."
                        InputProps={{
                          ...params.InputProps,
                          type: "search",
                        }}
                        onChange={(e) => handleSearch(e)}
                      />
                    )}
                    onChange={(e) => {
                      if (e.target.value !== "") {
                        searchEventHandler(e);
                      }
                      if (e.key === "Enter") {
                        searchEventHandler(e);
                      }
                    }}
                  />
                </Stack>
              </Toolbar>
              <Stack className="search-stack-div tablet-screen-search">
                <Autocomplete
                  freeSolo
                  id="free-solo-2-demo"
                  disableClearable
                  options={searchData.map((option) => {
                    return `${option.name},${option.region}`;
                  })}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Search the city name to see the weather..."
                      InputProps={{
                        ...params.InputProps,
                        type: "search",
                      }}
                      onChange={(e) => handleSearch(e)}
                    />
                  )}
                  onChange={(e) => {
                    if (e.target.value !== "") {
                      searchEventHandler(e);
                    }
                    if (e.key === "Enter") {
                      searchEventHandler(e);
                    }
                  }}
                />
              </Stack>
            </Container>
          </AppBar>
        </Box>
      )}
    </>
  );
}

export default Header;
