import { Box, Container, Grid, Link, Typography } from "@mui/material";
import React, { useContext } from "react";
import Divider from "@mui/material/Divider";
import "./Footer.scss";
import wpIcon from "../../../Assets/Icon/WHATSAPP.png";
import linkedinIcon from "../../../Assets/Icon/LINKEDIN.png";
import pinterestIcon from "../../../Assets/Icon/PINTREST.png";
import fbIcon from "../../../Assets/Icon/FACEBOOK.png";
import twitterIcon from "../../../Assets/Icon/TWITTER.png";
import footerLogo from "../../../Assets/Icon/mainLogoMausam.png";
import { useNavigate } from "react-router";
import { DataContext } from "../../../Context/DataContext";

function Footer() {
  const navigate = useNavigate();

  const { setIsHomePage } = useContext(DataContext);

  const OnLinkClickHandler = (link) => {
    navigate(link);
    setIsHomePage(link === "/" ? true : false);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <Box className="footer-box">
      <Container className="container-class">
        <Grid container>
          <Grid item xs={12} sm={6} md={3} className="address-details">
            <Grid>
              <Grid className="d-flex-align">
                <img
                  src={footerLogo}
                  alt="logo"
                  className="footer-logo"
                  onClick={() => OnLinkClickHandler("/")}
                />
              </Grid>
            </Grid>
            <Grid className="icon-div">
              <img
                src={fbIcon}
                alt="facebook-link"
                onClick={() => {
                  window.open(process.env.REACT_APP_FOOTER_FB_LINK, "_blank");
                }}
              />

              <img
                src={wpIcon}
                alt="whatsapp-link"
                onClick={() => {
                  window.open(process.env.REACT_APP_FOOTER_WP_LINK, "_blank");
                }}
              />
              <img
                src={twitterIcon}
                alt="twitter-link"
                onClick={() => {
                  window.open(
                    process.env.REACT_APP_FOOTER_TWITTER_LINK,
                    "_blank"
                  );
                }}
              />
              <img
                src={linkedinIcon}
                alt="linkedIn-link"
                onClick={() => {
                  window.open(
                    process.env.REACT_APP_FOOTER_LINKEDIN_LINK,
                    "_blank"
                  );
                }}
              />
              <img
                src={pinterestIcon}
                alt="pinterest-link"
                onClick={() => {
                  window.open(
                    process.env.REACT_APP_FOOTER_PINTREST_LINK,
                    "_blank"
                  );
                }}
              />
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            className="footer-main-title"
            sx={{ maxHeight: { xs: "auto", sm: "0px" } }}
          >
            <Typography className="item-title">
              <span onClick={() => OnLinkClickHandler("/contact-us")}>
                Contact Us
              </span>
            </Typography>

            <Typography className="item-title">
              <span onClick={() => OnLinkClickHandler("/privacy-policy")}>
                Privacy policy
              </span>
            </Typography>
            <Typography className="item-title">
              <span onClick={() => OnLinkClickHandler("/terms-of-service")}>
                Terms of service
              </span>
            </Typography>
            <Typography className="item-title">
              <span onClick={() => OnLinkClickHandler("/disclaimer")}>
                Disclaimer
              </span>
            </Typography>
          </Grid>
        </Grid>
      </Container>
      <Divider className="divider" />
      <Container className="container-class">
        <Grid className="footer-bottom">
          <Typography className="footer-bottom-line">
            &copy; 2024 Mausam All right reserved.
          </Typography>
          <Link
            className="footer-bottom-line"
            href={process.env.REACT_APP_FOOTER_DEVELOPER_LINK}
            target="_blank"
          >
            Developed by <span>www.pixerfect.com</span>
          </Link>
        </Grid>
      </Container>
    </Box>
  );
}

export default Footer;
