import React, { useContext } from "react";
import { Box, Grid } from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { DataContext } from "../../Context/DataContext";
import "./WebStories.scss";
import { useNavigate, useParams } from "react-router";
import { Helmet } from "react-helmet";
import { CONSTANT, SEO } from "../../Utils/Constant";

const SingleWebStory = () => {
  const { modalUrl, setModalUrl, webStoryViaHome, stories } =
    useContext(DataContext);
  const { webSlug } = useParams();
  const navigate = useNavigate();
  const closeIframeHandler = () => {
    setModalUrl(null);
    navigate(!webStoryViaHome ? "/mausam-web-stories" : "/");
  };
  return (
    <>
      <Helmet>
        <title>
          {stories?.filter((x) => x.slug === webSlug)[0]?.title
            ? `${
                stories?.filter((x) => x.slug === webSlug)[0]?.title
              }~web story`
            : SEO.SINGLE_WEB_STORY_TITLE}
        </title>
        <meta name="description" content={SEO.SINGLE_WEB_STORY_DESCRIPTION} />
        <meta name="keywords" content={SEO.SINGLE_WEB_STORY_KEYWORDS} />

        <meta property="og:title" content={SEO.SINGLE_WEB_STORY_TITLE} />
        <meta
          property="og:description"
          content={SEO.SINGLE_WEB_STORY_DESCRIPTION}
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={CONSTANT.WEATHER_SINGLE_WEB_LINK} />
        <meta property="og:site_name" content={CONSTANT.WEATHER_TIMES} />

        <meta name="twitter:title" content={SEO.SINGLE_WEB_STORY_TITLE} />
        <meta
          name="twitter:description"
          content={SEO.SINGLE_WEB_STORY_DESCRIPTION}
        />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <Grid className="modal-overlay" onClick={() => closeIframeHandler()}>
        <CloseOutlinedIcon onClick={() => closeIframeHandler()} />
        <Box className="iframe-div">
          <iframe
            src={
              modalUrl ||
              `${process.env.REACT_APP_WP_ENDPOINT}web-stories/${webSlug}/`
            }
            title="Web-Story"
          ></iframe>
        </Box>
      </Grid>
    </>
  );
};

export default SingleWebStory;
