import React from "react";
import "./ViewDetailsWithLabel.scss";
import { Grid, Typography } from "@mui/material";

function ViewDetailsWithLabel(props) {
  const { label, value, isFirst } = props;

  return (
    <Grid
      item
      xs={12}
      sm={5.7}
      className="display-flex-all-center view-detail-label-container"
      sx={{
        borderBottom: !isFirst ? "1px solid #ccc" : "1px solid transparent",
      }}
    >
      <Typography className="view-detail-label">{label}</Typography>
      <Typography>{value}</Typography>
    </Grid>
  );
}

export default ViewDetailsWithLabel;
