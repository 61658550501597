import { TableCell, tableCellClasses, TableRow } from "@mui/material";
import InputBase from "@mui/material/InputBase";
import { styled, alpha } from "@mui/material/styles";
import moment from "moment";
import Switch from "@mui/material/Switch";
// import bgImg1 from "../Assets/Icon/bgImg1.png";
// import bgImg2 from "../Assets/Icon/bgImg2.png";
// import bgImg3 from "../Assets/Icon/bgImg3.png";
// import bgImg4 from "../Assets/Icon/bgImg4.png";
// import bgImg5 from "../Assets/Icon/bgImg5.png";

export const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

export const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

export const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  width: "100%",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export const formatDate = (inputDate) => {
  var inputDateObj = new Date(inputDate);
  var monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  var day = inputDateObj.getDate();
  var monthIndex = inputDateObj.getMonth();
  var year = inputDateObj.getFullYear();
  var formattedDate = monthNames[monthIndex] + " " + day + ", " + year;
  return formattedDate;
};

export const decodeHTMLString = (htmlString) => {
  var decodedString = decodeURIComponent(htmlString);
  var tempElement = document.createElement("div");
  tempElement.innerHTML = decodedString;
  return tempElement.innerText || tempElement.textContent;
};

export const getNext7Days = () => {
  const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const today = new Date();
  const next7Days = [];

  for (let i = 0; i < 7; i++) {
    const nextDate = new Date(today);
    nextDate.setDate(today.getDate() + i);
    const dayName = days[nextDate.getDay()];
    const count = i + 1;
    next7Days.push({ dayName, count });
  }

  return next7Days;
};

export const extractOptionNumber = (inputString) => {
  // Find the position of "option-"
  let indexOption = inputString.indexOf("option-");

  if (indexOption !== -1) {
    // Extract substring starting from the index immediately after "option-"
    let numbersAfterOption = inputString.substring(
      indexOption + "option-".length
    );

    // Convert the substring to a number
    let optionNumber = parseInt(numbersAfterOption);
    if (!isNaN(optionNumber)) {
      return optionNumber;
    } else {
      return "Invalid option number format";
    }
  } else {
    return "Option not found in the string";
  }
};

export const getAirQualityLevel = (airQualityData) => {
  if (airQualityData?.co !== undefined && airQualityData?.co !== "") {
    const {
      co,
      no2,
      o3,
      so2,
      pm2_5,
      pm10,
      "us-epa-index": usEpaIndex,
      "gb-defra-index": gbDefraIndex,
    } = airQualityData;

    // Define thresholds for different air quality levels
    const thresholds = {
      Good: {
        co: 4.4,
        no2: 53,
        o3: 54,
        so2: 35,
        pm2_5: 12,
        pm10: 55,
        usEpaIndex: 1,
        gbDefraIndex: 1,
      },
      Poor: {
        co: 9.4,
        no2: 100,
        o3: 70,
        so2: 185,
        pm2_5: 35.4,
        pm10: 154,
        usEpaIndex: 2,
        gbDefraIndex: 3,
      },
      // Add more thresholds for other air quality levels if needed
    };

    // Check if any of the parameters exceed the thresholds
    for (let level in thresholds) {
      if (
        co > thresholds[level].co ||
        no2 > thresholds[level].no2 ||
        o3 > thresholds[level].o3 ||
        so2 > thresholds[level].so2 ||
        pm2_5 > thresholds[level].pm2_5 ||
        pm10 > thresholds[level].pm10 ||
        usEpaIndex > thresholds[level].usEpaIndex ||
        gbDefraIndex > thresholds[level].gbDefraIndex
      ) {
        return level;
      }
    }

    // If none of the levels were exceeded, return 'good'
    return "Good";
  } else {
    return "Good";
  }
};

export const differenceBetweenSunsetSunrise = (sunrise, sunset) => {
  const timeString1 = sunrise || "06:07 AM";
  const timeString2 = sunset || "07:04 PM";

  const time1 = moment(timeString1, "h:mm A");
  const time2 = moment(timeString2, "h:mm A");

  const diffInMinutes = time2.diff(time1, "minutes");

  const diffHours = Math.floor(diffInMinutes / 60);
  const diffMinutes = diffInMinutes % 60;

  return `${diffHours} hours ${diffMinutes} minutes`;
};

export const assessAirQuality = (airQualityData) => {
  let message = "The air is currently ";
  const thresholdCO = 10;
  const thresholdNO2 = 20;
  const thresholdO3 = 50;
  const thresholdSO2 = 15;
  const thresholdPM2_5 = 25;
  const thresholdPM10 = 50;

  if (
    airQualityData &&
    airQualityData.co !== undefined &&
    airQualityData.co !== ""
  ) {
    if (
      airQualityData.co > thresholdCO ||
      airQualityData.no2 > thresholdNO2 ||
      airQualityData.o3 > thresholdO3 ||
      airQualityData.so2 > thresholdSO2 ||
      airQualityData.pm2_5 > thresholdPM2_5 ||
      airQualityData.pm10 > thresholdPM10
    ) {
      message += "polluted and unhealthy for sensitive groups. ";
      message +=
        "Reduce time spent outside if you are feeling symptoms such as difficulty breathing or throat irritation.";
    } else if (
      airQualityData.us_epa_index === 3 ||
      airQualityData.gb_defra_index >= 7
    ) {
      message += "moderately polluted. ";
      message +=
        "Sensitive individuals may experience some discomfort, consider reducing outdoor activities if you feel any symptoms.";
    } else if (
      airQualityData.us_epa_index === 2 ||
      airQualityData.gb_defra_index >= 4
    ) {
      message += "moderately clean. ";
      message +=
        "The air quality is moderate, most people can spend time outside but sensitive groups may experience some irritation.";
    } else if (
      airQualityData.us_epa_index === 1 ||
      airQualityData.gb_defra_index >= 1
    ) {
      message += "clean and healthy. ";
      message +=
        "Air quality is good, suitable for outdoor activities for everyone.";
    } else {
      message += "clean and safe.";
    }

    return message;
  } else {
    return `The air has reached a high level of pollution and is unhealthy for
              sensitive groups. Reduce time spent outside if you are feeling
              symptoms such as difficulty breathing or throat irritation.`;
  }
};

export const getFirstAndLastDateOfMonth = (monthName, year) => {
  const firstDate = moment(`${monthName} ${year}`, "MMMM YYYY")
    .startOf("month")
    .format("YYYY-MM-DD");

  const endDate = moment(`${monthName} ${year}`, "MMMM YYYY")
    .endOf("month")
    .format("YYYY-MM-DD");

  return { firstDate: firstDate, endDate: endDate };
};

export const getMonthDates = (monthName, year) => {
  const monthIndex = new Date(Date.parse(monthName + " 1, " + year)).getMonth();
  const currentYear = parseInt(year);

  const today = new Date();
  const currentDay = today.getDate();
  const currentMonth = today.getMonth();
  const currentYearValue = today.getFullYear();

  const daysInMonth = new Date(currentYear, monthIndex + 1, 0).getDate();
  const firstDayOfMonth = new Date(currentYear, monthIndex, 0).getDay();
  const datesArray = [];
  let currentWeek = [];

  const daysInPreviousMonth = new Date(currentYear, monthIndex, 0).getDate();
  const prevMonthDaysToAdd = Math.min(firstDayOfMonth, 7);

  for (
    let i = daysInPreviousMonth - prevMonthDaysToAdd;
    i <= daysInPreviousMonth && currentWeek.length < 7;
    i++
  ) {
    let formattedDate = `${currentYear}-${monthIndex
      .toString()
      .padStart(2, "0")}-${i.toString().padStart(2, "0")}`;

    var match = formattedDate.match(/-(\d+)-/);
    var result = match ? match[1] : null;
    if (result === "00") {
      formattedDate = `${currentYear - 1}-${(monthIndex + 12)
        .toString()
        .padStart(2, "0")}-${i.toString().padStart(2, "0")}`;
    }
    if (formattedDate)
      currentWeek.push({
        date: formattedDate,
        temp1: 0,
        temp2: 0,
        pastDate: true,
      });
  }

  for (let i = 1; i <= daysInMonth; i++) {
    const currentDate = new Date(currentYear, monthIndex, i + 1);
    const formattedDate = currentDate.toISOString().slice(0, 10); // Format as "yyyy-mm-dd"
    const isToday =
      currentYearValue === currentYear &&
      currentMonth === monthIndex &&
      currentDay === i;

    const dayObject = {
      date: formattedDate,
      temp1: 0,
      temp2: 0,
      presentDate: isToday,
      futureDate: !isToday,
    };
    currentWeek.push(dayObject);

    if (currentWeek.length === 7) {
      datesArray.push(currentWeek);
      currentWeek = [];
    }
  }

  const lastDayOfMonth = new Date(
    currentYear,
    monthIndex,
    daysInMonth
  ).getDay();
  const nextMonthDays = lastDayOfMonth === 6 ? 0 : 6 - lastDayOfMonth;
  // Add dates from the next month only if there's space in the current week
  for (let i = 1; i <= nextMonthDays; i++) {
    const formattedDate = `${currentYear}-${(monthIndex + 2)
      .toString()
      .padStart(2, "0")}-${i.toString().padStart(2, "0")}`;

    currentWeek.push({
      date: formattedDate,
      temp1: 0,
      temp2: 0,
      futureDate: true,
    });
  }

  if (currentWeek.length > 0) {
    datesArray.push(currentWeek);
  }

  return datesArray;
};

export const generateMinutesArray = (weatherData, isFahrenheitValue) => {
  const start = moment().startOf("day").hour(0).minute(0);
  const end = moment().endOf("day").hour(23).minute(59);
  const result = [];

  let current = start.clone();
  let index = 1;
  while (current.isBefore(end)) {
    const halfHourStart = current.clone();
    const halfHourEnd = current.clone().add(29, "minutes");

    const intervalTitle = `${halfHourStart.format(
      "h:mm A"
    )} - ${halfHourEnd.format("h:mm A")}`;

    const data = [];
    let tempSum = 0;
    let tempCount = 0;
    while (current.isBefore(halfHourEnd)) {
      const currentWeather = weatherData.find((entry) =>
        moment(entry.time).isSame(current, "hour")
      );
      if (currentWeather) {
        tempSum += isFahrenheitValue
          ? currentWeather.temp_f
          : currentWeather.temp_c;
        tempCount++;
        const time = current.format("h:mm A");
        const status = currentWeather.condition.text;
        const icon = currentWeather.condition.icon;
        const temp = isFahrenheitValue
          ? currentWeather.temp_f
          : currentWeather.temp_c;
        data.push({ time, status, icon, temp });
      }
      current.add(1, "minutes");
    }
    const averageTemp = tempCount > 0 ? tempSum / tempCount : null;

    result.push({
      index: index.toString(),
      title: intervalTitle,
      data: data,
      averageTemp: averageTemp,
    });

    index++;
  }

  return result;
};

export const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#black" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

export const formatDateDifference = (date) => {
  const modifyDate = moment(date).add(5, "hours").add(30, "minutes");
  const diff = moment().diff(moment(modifyDate));
  // const diff = moment().diff(moment(date));

  // Get the duration in hours, days, weeks, months, or years
  const duration = moment.duration(diff);

  if (duration.asHours() < 1) {
    return `${Math.floor(duration.asMinutes())} minutes ago`;
  } else if (duration.asHours() < 24) {
    return `${Math.floor(duration.asHours())} hours ago`;
  } else if (duration.asDays() < 7) {
    return `${Math.floor(duration.asDays())} days ago`;
  } else if (duration.asWeeks() < 4) {
    return `${Math.floor(duration.asWeeks())} weeks ago`;
  } else if (duration.asMonths() < 12) {
    return `${Math.floor(duration.asMonths())} months ago`;
  } else {
    return `${Math.floor(duration.asYears())} years ago`;
  }
};

export const setCookie = (name, value, days) => {
  let expires = "";
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
};

export const getCookie = (name) => {
  const nameEQ = name + "=";
  const cookies = document.cookie.split(";");
  for (let i = 0; i < cookies.length; i++) {
    let cookie = cookies[i];
    while (cookie.charAt(0) === " ") {
      cookie = cookie.substring(1, cookie.length);
    }
    if (cookie.indexOf(nameEQ) === 0) {
      return JSON.parse(cookie.substring(nameEQ.length, cookie.length));
    }
  }
  return null;
};

export const eraseCookie = (name) => {
  document.cookie = name + "=; Max-Age=-99999999;";
};

export const showFiveThreeElement = (mainArray, category, flag) => {
  if (mainArray.length > 0) {
    const filterArray = mainArray.filter((x) =>
      x?.categories.includes(category)
    );
    let finalArray = [];

    if (flag) {
      if (filterArray.length >= 6) {
        finalArray = filterArray.slice(filterArray.length - 5);
      } else if (filterArray.length <= 5) {
        finalArray = filterArray;
      }
      return finalArray;
    }
  }
};

export const weatherApiCheckOrRedirect = () => {
  if (
    window.location.pathname === "/today-mausam" ||
    window.location.pathname === "/hourly-mausam" ||
    window.location.pathname === "/daily-mausam" ||
    window.location.pathname === "/minutecast-mausam" ||
    window.location.pathname === "/monthly-mausam" ||
    window.location.pathname === "/mausam-specified-date"
  ) {
    return true;
  } else {
    return false;
  }
};

export const weatherApiCheckForPrivacyRoute = () => {
  if (
    window.location.pathname === "/privacy-policy" ||
    window.location.pathname === "/contact-us" ||
    window.location.pathname === "/terms-of-service" ||
    window.location.pathname === "/disclaimer"
  ) {
    return true;
  } else {
    return false;
  }
};

export const modifyBlogsArrayForView = (inputArray) => {
  let outputArray = [];
  let index = 0;

  if (inputArray.length > 0) {
    while (index < inputArray.length) {
      if (index === 0) {
        outputArray.push([inputArray[index]]);
        index++;
      } else if (index === 1) {
        if (inputArray[index + 1] !== undefined) {
          outputArray.push([inputArray[index], inputArray[index + 1]]);
          index += 2;
        } else {
          outputArray.push([inputArray[index]]);
          index += 2;
        }
      } else if (index === 3) {
        let nestedArray = [];
        let x = 1;
        while (inputArray[index + x] !== undefined) {
          if (x === 1) {
            nestedArray.push([inputArray[index], inputArray[index + x]]);
            x++;
          } else {
            let subArray = [inputArray[index + x], inputArray[index + x + 1]];
            if (inputArray[index + x + 2] !== undefined) {
              subArray.push(inputArray[index + x + 2]);
            }
            if (inputArray[index + x + 3] !== undefined) {
              subArray.push(inputArray[index + x + 3]);
            }
            nestedArray.push(subArray);
            x += 4;
          }
        }
        outputArray.push(nestedArray);

        inputArray.length > 9 &&
          outputArray[2].length > 1 &&
          outputArray[2].pop();

        index += 6;
      } else {
        const remainingElementsArray = [];

        for (let i = index; i < inputArray.length; i++) {
          remainingElementsArray.push(inputArray[i]);
        }
        outputArray.push(remainingElementsArray);

        break;
      }
    }
  }

  return outputArray;
};

// export const returnRandomBgImg = () => {
//   const hour = new Date().getHours();
//   const imgArray = [bgImg1, bgImg2, bgImg3, bgImg4, bgImg5];

//   let index;
//   if (hour >= 0 && hour < 6) {
//     index = 0; // Use bgImg1 between midnight and 6 AM
//   } else if (hour >= 6 && hour < 12) {
//     index = 1; // Use bgImg2 between 6 AM and 12 PM
//   } else if (hour >= 13 && hour < 16) {
//     index = 2; // Use bgImg3 between 1 PM and 4 PM
//   } else if (hour >= 16 && hour < 19) {
//     index = 3; // Use bgImg3 between 4 PM and 7 PM
//   } else {
//     index = 4; // Use bgImg4 between 7 PM and midnight
//   }

//   return imgArray[index];
// };

export const getYearsArray = (startYear) => {
  const currentYear = new Date().getFullYear();
  const yearsArray = [];

  for (let year = startYear; year <= currentYear; year++) {
    yearsArray.push(year);
  }

  return yearsArray;
};

export const getMonthNamesForYear = (year) => {
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth(); // 0-based index
  if (year < currentYear) {
    // If the year is in the past, return all months
    return monthNames;
  } else if (year === currentYear) {
    // If the year is the current year, return past, current, and next month
    const monthsArray = [];
    for (let i = 0; i <= currentMonth + 1 && i < 12; i++) {
      monthsArray.push(monthNames[i]);
    }
    return monthsArray;
  } else {
    return [];
  }
};

export const formatWeatherAlert = (alertText, date) => {
  // Split the input text into lines
  const lines = alertText.split("\n");

  // Initialize an empty string to store the formatted HTML
  let html = "";
  if (date !== undefined) {
    html += `<p><b>Effective Date</b> : ${moment(date).format(
      "YYYY-MM-DD"
    )}</p>`;
  }
  // Process each line
  lines.forEach((line) => {
    // Remove leading and trailing whitespace
    line = line.trim();

    // Skip empty lines
    if (line === "") {
      return;
    }

    // Extract key and value before the ellipsis and format into HTML
    const match = line.match(/\* (.+?)\.\.\.([^]+)$/);
    if (match) {
      const key = match[1].trim();
      const value = match[2].trim();
      html += `<p><b>${key}</b> : ${value}</p>`;
    }
  });

  return html;
};


export const updateYoastHeadUrls = (yoast_head) => {
  if (typeof yoast_head !== "string") {
    throw new TypeError("The yoast_head parameter must be a string.");
  }

  // Extract the JSON-LD script content
  let ldJsonStart = yoast_head.indexOf('<script type="application/ld+json"');
  let ldJsonEnd = ldJsonStart === -1 ? -1 : yoast_head.indexOf("</script>", ldJsonStart) + 9;

  let ldJsonContent = ldJsonStart === -1 ? "" : yoast_head.substring(ldJsonStart, ldJsonEnd);
  let jsonString = ldJsonContent.match(/<script[^>]*>([\s\S]*?)<\/script>/)?.[1];

  let jsonLdObject;
  try {
    jsonLdObject = JSON.parse(jsonString);
  } catch (error) {
    console.error("Failed to parse JSON-LD content:", error);
    jsonLdObject = null;
  }

  // Regular expression to match URLs with subdomains and optional date components
  const urlPattern = /https:\/\/api\.mausam\.net\/(\d{4}\/\d{2}\/\d{2}\/)?/g;
  const arrayUrlPattern = /http:\/\/api\.mausam\.net/g;

  // Function to recursively update URLs in the JSON-LD object
  function updateUrls(obj) {
    if (Array.isArray(obj)) {
      // If obj is an array, update each string element
      return obj.map(item => (typeof item === "string" ? item.replace(arrayUrlPattern, "https://mausam.net") : updateUrls(item)));
    } else if (typeof obj === "object" && obj !== null) {
      // If obj is an object, iterate over its properties
      for (let key in obj) {
        if (typeof obj[key] === "string") {
          obj[key] = obj[key].replace(urlPattern, "https://mausam.net/mausam-news-detail/");
        } else if (typeof obj[key] === "object") {
          obj[key] = updateUrls(obj[key]);
        }
      }
    }
    return obj;
  }

  // Update URLs in the JSON-LD object if it exists
  if (jsonLdObject) {
    updateUrls(jsonLdObject);
  }

  // Convert the updated JSON-LD object back to a string
  let updatedJsonString = JSON.stringify(jsonLdObject || {});

  // Replace the original JSON-LD script in the yoast_head with the updated one
  let updatedLdJsonContent = ldJsonContent.replace(jsonString, updatedJsonString);
  let updatedYoastHead = yoast_head.replace(ldJsonContent, updatedLdJsonContent);

  // Extract and update the <meta> tag content
  updatedYoastHead = updatedYoastHead.replace(urlPattern, "https://mausam.net/");

  return updatedYoastHead;
};