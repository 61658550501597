import React, { useEffect, useState } from "react";
import "./SingleBlog.scss";
import { Grid, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import { formatDate, updateYoastHeadUrls } from "../../../Utils/CommonFunction";
import BlogCategoryLink from "../../Common/BlogRedirectLink/BlogCategoryLink";
import axios from "axios";
import SmallLoader from "../../Common/Loader/SmallLoader";
import { Helmet } from "react-helmet";

function SingleBlog() {
  const [blog, setBlog] = useState({});
  let { BlogSlug } = useParams();

  useEffect(() => {
    getBlogByID(BlogSlug);
  }, [BlogSlug]);
  const getBlogByID = (slug) => {
    try {
      axios
        .get(
          `${process.env.REACT_APP_WP_ENDPOINT}wp-json/weather-api/v1/posts/${slug}`
        )
        .then((res) => {
          setBlog(res?.data || {});
        })
        .catch((err) => {
          console.log(err);
          setBlog({});
        });
    } catch (e) {
      console.log(e);
    }
  };
  const parser = new DOMParser();
  const doc = parser.parseFromString(
    blog?.yoast_head ? updateYoastHeadUrls(blog?.yoast_head) : "",
    "text/html"
  );
  const headElements = Array.from(doc.head.children);
  return (
    <>
      <Helmet>
        {headElements.map((element, index) => {
          // Clone the element to use as React component
          const attributes = Array.from(element.attributes).reduce(
            (acc, attr) => {
              acc[attr.name] = attr.value;
              return acc;
            },
            {}
          );

          // Check the tag name and create corresponding React component
          switch (element.tagName) {
            case "TITLE":
              return <title key={index}>{element.textContent}</title>;
            case "META":
              return <meta key={index} {...attributes} />;
            case "SCRIPT":
              return (
                <script key={index} {...attributes} type="application/ld+json">
                  {element.textContent}
                </script>
              );
            default:
              return null;
          }
        })}
      </Helmet>
      {Object.keys(blog)?.length > 0 ? (
        <>
          <Grid container>
            <Grid item xs={12} className="blog-container animation-zoom-in">
              <Grid className="blog-content">
                <Typography
                  variant="h1"
                  dangerouslySetInnerHTML={{ __html: blog?.title?.rendered }}
                />
                <Typography>{formatDate(blog?.modified)}~By Mausam</Typography>
              </Grid>
              {blog?.featured_image_url !== false && (
                <Grid sx={{ my: 3 }}>
                  <img
                    src={blog?.featured_image_url}
                    alt="blog-img"
                    className="blog-img"
                  />
                </Grid>
              )}
              <Grid
                className="blog-content"
                dangerouslySetInnerHTML={{
                  __html: blog?.content?.rendered,
                }}
                sx={{ mb: 5 }}
              ></Grid>
              <BlogCategoryLink />
              
            </Grid>
          </Grid>
        </>
      ) : (
        <Grid className="small-loader-component-div">
          <SmallLoader />
        </Grid>
      )}
    </>
  );
}

export default SingleBlog;
