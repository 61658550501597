import React, { useEffect, useContext } from "react";
import { Grid, Typography } from "@mui/material";
import "./WebStories.scss";
import { DataContext } from "../../Context/DataContext";
import { useNavigate } from "react-router";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { formatDateDifference } from "../../Utils/CommonFunction";
import SmallLoader from "../Common/Loader/SmallLoader";

const WebStories = () => {
  const {
    setModalUrl,
    setIsHomePage,
    stories,
    fetchStories,
    setWebStoryViaHome,
    webStoryLoader,
    setWebStoryPage,
  } = useContext(DataContext);
  const navigate = useNavigate();

  useEffect(() => {
    fetchStories(1, 4);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const seeMoreClickHandler = () => {
    setIsHomePage(false);
    setWebStoryPage(1);
    navigate(`/mausam-web-stories`);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <>
      {webStoryLoader || stories?.length <= 0 ? (
        <Grid className="small-loader-component-div  web-story-homepage">
          <SmallLoader />
        </Grid>
      ) : (
        <Grid className="web-story-homepage">
          <Grid
            item
            xs={12}
            className="d-flex-align-center space-btn web-story-headline-div"
          >
            <Typography variant="h2">Web Stories</Typography>
            <Typography
              className="d-flex-align-center gap-6 cursor-pointer"
              onClick={(e) => seeMoreClickHandler()}
            >
              See more <ArrowForwardIcon />
            </Typography>
          </Grid>
          <Grid container className="card-main-div">
            {stories?.map((value, i) => (
              <Grid
                className="web-stories-cards animation-zoom-in"
                sx={{
                  cursor: "pointer",
                  margin: "4px auto",
                }}
                key={i}
                onClick={() => {
                  setModalUrl(
                    `${process.env.REACT_APP_WP_ENDPOINT}web-stories/${value?.slug}/`
                  );
                  setWebStoryViaHome(true);
                  navigate(`/mausam-web-stories/${value?.slug}`);
                }}
              >
                <img
                  src={value?.thumbnail}
                  alt={value?.alt || "web-story-thumbnail"}
                  height={622}
                  width={370}
                  style={{ borderRadius: "8px" }}
                />
                <div className="web-stories-detail ">
                  <p className="story-title">{value?.title || ""}</p>
                  <p className="story-time">
                    {formatDateDifference(value?.time || "2024-06-17 03:22:26")}
                  </p>
                </div>
              </Grid>
            ))}
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default WebStories;
