import React, { useContext } from "react";
import "./HourlyWeatherCard.scss";
import { Grid, Typography } from "@mui/material";
import ViewDetailsWithLabel from "../ViewDetailsWithLabel/ViewDetailsWithLabel";
import moment from "moment";
import rainDropIcon from "../../../../Assets/Icon/RainDrop.svg";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { DataContext } from "../../../../Context/DataContext";
import { useNavigate } from "react-router";
function HourlyWeatherCard(props) {
  const {
    weatherStatus,
    hourInterval,
    titleTemp,
    minTemp,
    rainPossibility,
    labelValueArray,
    flag,
    imgIcon,
  } = props;

  const { setIsTodayWeatherShow } = useContext(DataContext);
  const navigate = useNavigate();

  const navigateToSpecificDate = () => {
    setIsTodayWeatherShow(moment(hourInterval).format("YYYY-MM-DD"));
    navigate(`/mausam-specified-date`);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <Grid className="hourly-card-container">
      <Grid
        container
        className="display-flex-all-center hourly-card-title"
        onClick={(e) => navigateToSpecificDate()}
      >
        <Grid
          item
          xs={12}
          sm={4}
          className={
            flag !== "daily"
              ? "display-flex-all-center hourly-card-title-1"
              : "display-flex-all-center hourly-card-title-1"
          }
        >
          <Grid className="ten-day-weather-container-bottom-container-div-two">
            {flag !== "daily" ? (
              <Typography className="p-1">
                {moment(hourInterval).format("h A")}
              </Typography>
            ) : (
              <Grid className="daily-weather-card-date">
                <Typography className="p-1">
                  {moment(hourInterval).format("ddd")}
                </Typography>
                <Typography className="p-2">
                  {moment(hourInterval).format("M/DD")}
                </Typography>
              </Grid>
            )}
          </Grid>
          <Grid className="display-flex-all-center ten-day-weather-container-bottom-container-div-three">
            <img
              src={imgIcon}
              alt={weatherStatus}
              height={flag !== "daily" ? "64px" : "62px"}
              className="hourly-card-img"
            />
            <Typography variant="h3">{titleTemp}°</Typography>
            {flag === "daily" && <Typography>/{minTemp}°</Typography>}
          </Grid>
        </Grid>
        <Grid item xs={6} sm={4} sx={{ display: { xs: "block", sm: "none" } }}>
          <Typography>{weatherStatus}</Typography>
        </Grid>
        <Grid
          item
          xs={6}
          sm={4}
          className="ten-day-weather-container-bottom-container-div-five"
        >
          <Typography className="tab-title-rain-possibility">
            <img src={rainDropIcon} alt="rainDrop" height="12px" />
            {rainPossibility}%
            {flag === "daily" && (
              <ArrowForwardIcon className="arrow-icon-right-daily-weather-card" />
            )}
          </Typography>
        </Grid>
      </Grid>
      <Grid sx={{ display: { xs: "none", sm: "block" } }}>
        <Typography>{weatherStatus}</Typography>
      </Grid>
      <Grid container sx={{ justifyContent: "space-between", mt: 2 }}>
        {labelValueArray.map((item, index) => {
          const isFirst =
            labelValueArray?.length % 2 === 0
              ? index === labelValueArray?.length - 2 ||
                index === labelValueArray?.length - 1
              : index === labelValueArray?.length - 1;

          return (
            <ViewDetailsWithLabel
              label={item?.label}
              value={item?.value}
              isFirst={isFirst}
            />
          );
        })}
      </Grid>
    </Grid>
  );
}

export default HourlyWeatherCard;
