import { Grid } from "@mui/material";
import React from "react";
import "./Loader.scss";

function SmallLoader(props) {
  const { flag } = props;
  return (
    <Grid className="small-loader-div">
      <span
        className="loader-small"
        style={{ borderColor: !flag && "black" }}
      ></span>
    </Grid>
  );
}

export default SmallLoader;
