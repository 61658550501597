import React, { useContext, useEffect, useState } from "react";
import "./BlogCategoryLink.scss";
import { Grid, Typography } from "@mui/material";
import TrendingFlatIcon from "@mui/icons-material/TrendingFlat";
import { useNavigate } from "react-router";
import { DataContext } from "../../../Context/DataContext";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

function BlogCategoryLink(props) {
  const { category, setIsHomePage, setCategoryBlogViewRenderViaUrl } =
    useContext(DataContext);
  const navigate = useNavigate();
  const [linkData, setLinkData] = useState([]);
  useEffect(() => {
    if (category.length > 0) {
      setLinkData(category);
    } else if (props?.linkData) {
      setLinkData(props.linkData);
    } else {
      setLinkData([]);
    }
  }, [category, props.linkData]);

  const onClickHandler = (category_slug) => {
    setCategoryBlogViewRenderViaUrl(true);
    setIsHomePage(false);
    navigate(`/mausam-news/${category_slug}`);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    linkData?.length > 0 && (
      <>
        <Grid
          item
          xs={12}
          className="previous-btn-div"
          onClick={() => {
            setCategoryBlogViewRenderViaUrl(true);
            window?.history.back();
          }}
          sx={{ mt: 2, mb: 4 }}
        >
          <KeyboardBackspaceIcon />
          <Typography>Previous</Typography>
          <Grid />
        </Grid>
        <Typography sx={{ my: 0.5 }}>Topics</Typography>
        {linkData?.map((item, index) => (
          <Grid
            className="display-flex-all-center view-link-container"
            onClick={() => onClickHandler(item?.category_slug)}
            key={index}
          >
            <Typography className="link">{item?.category_name}</Typography>
            <TrendingFlatIcon />
          </Grid>
        ))}
      </>
    )
  );
}

export default BlogCategoryLink;
