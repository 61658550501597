import React, { useContext } from "react";
import "./HourlyLinkLabel.scss";
import { Grid, Typography } from "@mui/material";
import TrendingFlatIcon from "@mui/icons-material/TrendingFlat";
import { DataContext } from "../../../../Context/DataContext";
import { useNavigate } from "react-router";

function HourlyLinkLabel(props) {
  const {
    label,
    count,
    isLink = false,
    isSameState = {},
    setMonth,
    setYear,
    setFlagState,
    flagState,
  } = props;
  const { setLinkClicked, setDayIndex } = useContext(DataContext);
  const navigate = useNavigate();

  const onClickHandler = () => {
    if (setFlagState) {
      setFlagState(!flagState);
    }
    if (Object.keys(isSameState)?.length > 0) {
      setMonth(isSameState?.month);
      setYear(isSameState?.year);
      window.scrollTo({
        top: 140,
        behavior: "smooth",
      });
    } else {
      setLinkClicked(true);
      if (label === "Hourly" || label === "Daily" || label === "Monthly") {
        if (label === "Hourly") {
          setDayIndex(0);
          navigate("/hourly-mausam");
        } else if (label === "Daily") {
          navigate("/daily-mausam");
        } else if (label === "Monthly") {
          navigate("/monthly-mausam");
        }
      } else {
        navigate(`/hourly-mausam?day=${label}`);
        window.scrollTo({
          top: 50,
          behavior: "smooth",
        });
        setDayIndex(count - 1);
      }
    }
  };
  return (
    <Grid
      className="display-flex-all-center view-link-container"
      sx={{ cursor: isLink ? "pointer" : "auto" }}
      onClick={onClickHandler}
    >
      <Typography className={isLink ? "link" : "label"}>{label}</Typography>
      {isLink && <TrendingFlatIcon />}
    </Grid>
  );
}

export default HourlyLinkLabel;
