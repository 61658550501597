import { Routes, Route, useNavigate } from "react-router-dom";
import Header from "./Component/Pages/Header/Header";
import Footer from "./Component/Pages/Footer/Footer";
import "./App.scss";
import { Box, Container, Grid, Tab, Tabs, tabsClasses } from "@mui/material";
import Error from "./Component/Pages/404/404";
import TodayWeather from "./Component/Pages/TodayWeather/TodayWeather";
import { useContext, useEffect } from "react";
import { DataContext } from "./Context/DataContext";
import HourlyWeather from "./Component/Pages/HourlyWeather/HourlyWeather";
import DailyWeather from "./Component/Pages/DailyWeather/DailyWeather";
import MinutecastWeather from "./Component/Pages/MinutecastWeather/MinutecastWeather";
import MonthlyWeather from "./Component/Pages/MonthlyWeather/MonthlyWeather";
import DateWiseWeather from "./Component/Common/DateWiseWeather/DateWiseWeather";
import FormGroup from "@mui/material/FormGroup";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { IOSSwitch } from "./Utils/CommonFunction";
import BlogComponent from "./Component/Common/BlogContainer/BlogComponent";
import BottomInfiniteSlider from "./Component/Common/BlogContainer/BottomInfiniteSlider";
import HomePage from "./Component/Pages/HomePage/HomePage";
import BlogPageView from "./Component/Pages/BlogPages/BlogPageView";
import SingleBlog from "./Component/Pages/SingleBlog/SingleBlog";
import PrivacyPolicy from "./Component/Pages/PrivacyPolicy&TS/PrivacyPolicy";
import TermsOfService from "./Component/Pages/PrivacyPolicy&TS/TermsOfService";
import ContactUs from "./Component/Pages/PrivacyPolicy&TS/ContactUs";
import Disclaimer from "./Component/Pages/PrivacyPolicy&TS/Disclaimer";
import SingleWebStory from "./Component/WebStory/SingleWebStory";
import WebStoryFullView from "./Component/WebStory/WebStoryFullView";
import WebStories from "./Component/WebStory/WebStories";
import { KeywordArrayEnglish } from "./Utils/Constant";
import FooterPageComponent from "./Component/Pages/HomePageFooterPage/FooterPageComponent";

function App() {
  const {
    tabbingValue,
    setTabbingValue,
    fetchUserLocation,
    setIsFahrenheitValue,
    isFahrenheitValue,
    isHomePage,
    showWeatherTabbing,
    isLoading,
    isPrivacyPage,
  } = useContext(DataContext);
  const navigate = useNavigate();
  const handleChange = (event, newValue) => {
    setTabbingValue(newValue);
    if (newValue === 0) {
      navigate("/today-mausam");
    } else if (newValue === 1) {
      navigate("/hourly-mausam");
    } else if (newValue === 2) {
      navigate("/daily-mausam");
    } else if (newValue === 3) {
      navigate("/minutecast-mausam");
    } else if (newValue === 4) {
      navigate("/monthly-mausam");
    }
  };

  const googleTranslateElementInit = () => {
    new window.google.translate.TranslateElement(
      {
        pageLanguage: "en",
        autoDisplay: false,
      },
      "google_translate_element"
    );
  };

  useEffect(() => {
    fetchUserLocation();

    var addScript = document.createElement("script");
    addScript.setAttribute(
      "src",
      "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
    );
    document.body.appendChild(addScript);
    window.googleTranslateElementInit = googleTranslateElementInit;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const tempUnitChangeHandler = (e) => {
    setIsFahrenheitValue(e.target.checked);
  };

  return (
    <div className="App">
      <div className="App-div-1">
        <Header />

        <Container className="container-class">
          {!isHomePage && showWeatherTabbing && (
            <Grid className="tabbing-parent-div">
              <Tabs
                value={tabbingValue || 0}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons
                aria-label="visible arrows tabs example"
                sx={{
                  [`& .${tabsClasses.scrollButtons}`]: {
                    "&.Mui-disabled": { opacity: 0.3 },
                  },
                  width: "90%",
                }}
              >
                <Tab label="TODAY" />
                <Tab label="HOURLY" />
                <Tab label="DAILY" />
                <Tab label="MINUTECAST" />
                <Tab label="MONTHLY" />
              </Tabs>              
            </Grid>
          )}
          <FormGroup className="switch-btn-app">
                <Stack
                  direction="row"
                  spacing={1}
                  alignItems="center"
                  className="notranslate"
                >
                  <Typography
                    sx={{
                      color: isFahrenheitValue ? "black" : "green",
                      opacity: isFahrenheitValue ? 0.4 : 1,
                      transform: isFahrenheitValue && "scale(0.8)",
                    }}
                  >
                    °C
                  </Typography>
                  <IOSSwitch
                    inputProps={{ "aria-label": "ant design" }}
                    onChange={(e) => tempUnitChangeHandler(e)}
                  />
                  <Typography
                    sx={{
                      color: !isFahrenheitValue ? "black" : "green",
                      opacity: !isFahrenheitValue ? 0.4 : 1,
                      transform: !isFahrenheitValue && "scale(0.8)",
                    }}
                  >
                    °F
                  </Typography>
                </Stack>
              </FormGroup>
          <Box
            className="home-card"
            sx={{
              display: "flex",
              justifyContent: {
                xs: "center",
                sm: "flex-start",
              },
            }}
          >
            <Grid container sx={{ mt: { xs: 2, sm: 1, lg: 3 } }}>
              <Grid
                item
                xs={12}
                sm={8}
                sx={{ margin: isLoading ? "auto" : "unset" }}
              >
                <Routes>
                  <Route path="/" element={<HomePage />} />
                  <Route path="/mausam-news-detail" element={<HomePage />} />
                  <Route
                    path="/mausam-news/:cat_slug"
                    element={<BlogPageView />}
                  />
                  <Route
                    path="/mausam-news-detail/:BlogSlug"
                    element={<SingleBlog />}
                  />
                  <Route
                    path="/mausam-web-stories/:webSlug"
                    element={<SingleWebStory />}
                  />
                  <Route
                    path="/mausam-web-stories"
                    element={<WebStoryFullView />}
                  />
                  <Route
                    path={`/mausam-specified-date`}
                    element={<DateWiseWeather />}
                  />
                  <Route path="/today-mausam" element={<TodayWeather />} />
                  <Route path="/hourly-mausam" element={<HourlyWeather />} />
                  <Route path="/daily-mausam" element={<DailyWeather />} />
                  <Route
                    path="/minutecast-mausam"
                    element={<MinutecastWeather />}
                  />
                  <Route path="/monthly-mausam" element={<MonthlyWeather />} />
                  <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                  <Route path="/contact-us" element={<ContactUs />} />
                  <Route
                    path="/terms-of-service"
                    element={<TermsOfService />}
                  />
                  <Route path="/disclaimer" element={<Disclaimer />} />
                  <Route path="/*" element={<Error />} />
                  <Route
                    path="/mausam-news/uncategorized"
                    element={<Error />}
                  />
                </Routes>
              </Grid>
              {!isPrivacyPage && (
                <>
                  <Grid item xs={12} sm={0.5} />
                  <Grid item xs={12} sm={3.5}>
                    <BlogComponent />
                  </Grid>
                  {window?.location?.pathname === "/" && (
                    <Grid item xs={12}>
                      <WebStories />
                    </Grid>
                  )}
                  <Grid className="app-bottom-slider-outer-div">
                    <BottomInfiniteSlider />
                  </Grid>
                  <Grid item xs={12}></Grid>
                </>
              )}
            </Grid>
            <Grid className="keywords-div-hindi">
              {KeywordArrayEnglish.map((item, index) => (
                <Grid
                  className="keyword-card"
                  onClick={() => {
                    window.scrollTo({
                      top: 0,
                      behavior: "smooth",
                    });
                    navigate(item?.path);
                  }}
                >
                  {item?.title}
                </Grid>
              ))}
            </Grid>
            <FooterPageComponent />
          </Box>
        </Container>
      </div>
      <Footer />
      <Grid
        id="google_translate_element"
        className="google-translate-btn"
      ></Grid>
    </div>
  );
}

export default App;
