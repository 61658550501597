import { Divider, Grid, Typography } from "@mui/material";
import React, { useContext } from "react";
import "./BlogComponent.scss";
import { DataContext } from "../../../Context/DataContext";
import {
  formatDateDifference,
  showFiveThreeElement,
} from "../../../Utils/CommonFunction";
import { useNavigate } from "react-router";
import SmallLoader from "../Loader/SmallLoader";
import placeholderImg from "../../../Assets/Icon/placeholder.png"

function BlogSideBarCard(props) {
  const { title, StaticBlogList, slug } = props;
  const {
    setShowWeatherTabbing,
    setIsHomePage,
    setCategoryBlogViewRenderViaUrl,
  } = useContext(DataContext);
  const navigate = useNavigate();

  const openSingleBlogViewHandler = (id) => {
    setShowWeatherTabbing(false);
    setIsHomePage(false);
    navigate(`/mausam-news-detail/${id}`);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const seeMoreClickHandler = () => {
    setCategoryBlogViewRenderViaUrl(true);
    setIsHomePage(false);
    navigate(`/mausam-news/${slug}`);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <Grid className="blog-sidebar-container-1 animation-zoom-in">
      <Typography className="blog-sidebar-heading">{title}</Typography>
      <Divider />

      {StaticBlogList?.length > 0 ? (
        showFiveThreeElement(StaticBlogList, title, true).map((item, index) => (
          <div key={index}>
            <Grid
              className="blog-data-grid"
              // key={index}
              onClick={(e) => openSingleBlogViewHandler(item?.slug)}
            >
              <Grid className="blog-data">
                <Typography className="blog-data-category">
                  {item?.categories}
                </Typography>
                <Typography
                  className="blog-data-time"
                  dangerouslySetInnerHTML={{ __html: item?.title }}
                ></Typography>
                <Typography className="blog-data-post-time">
                  {formatDateDifference(item?.modified)}
                </Typography>
              </Grid>
              <Grid className="blog-data-img">
                <img
                  src={
                    item?.thumbnail !== false ? item?.thumbnail : placeholderImg
                  }
                  alt="blog-img"
                  height="64px"
                />
              </Grid>
            </Grid>
            <Divider />
          </div>
        ))
      ) : (
        <Grid className="small-loader-component-div">
          <SmallLoader />
        </Grid>
      )}
      <Typography
        className="blog-sidebar-more-story"
        onClick={() => seeMoreClickHandler()}
      >
        More Stories
      </Typography>
    </Grid>
  );
}

export default BlogSideBarCard;
