import React, { useEffect, useState } from "react";
import "./DateWiseWeather.scss";
import { useContext } from "react";
import { DataContext } from "../../../Context/DataContext";
import Box from "@mui/material/Box";
import Tabs, { tabsClasses } from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Divider, Grid, Tooltip, Typography } from "@mui/material";
import moment from "moment";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import DateWiseWeatherCard from "./DateWiseWeatherCard";
import iconSVG from "../../../Assets/Icon/TodaySun.svg";
import {
  differenceBetweenSunsetSunrise,
  getAirQualityLevel,
} from "../../../Utils/CommonFunction";
import moonPhaseIcon from "../../../Assets/Icon/MoonPhase.svg";
import HourlyLinkLabel from "../HourlyWeather/HourlyLinkLabel/HourlyLinkLabel";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { Helmet } from "react-helmet";
import { CONSTANT, SEO } from "../../../Utils/Constant";

function DateWiseWeather() {
  const {
    isTodayWeatherShow,
    setIsTodayWeatherShow,
    tenDaysForecast,
    getSpecificDateForecast,
    currentWeatherShow,
    isFahrenheitValue,
  } = useContext(DataContext);

  const [value, setValue] = useState(0);
  const [weatherValue, setWeatherValue] = useState({});
  const [noDataFound, setNoDataFound] = useState(false);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (tenDaysForecast) {
      let tempData = tenDaysForecast?.forecast?.forecastday?.filter(
        (item) =>
          moment(item?.date).format("YYYY-MM-DD") ===
          moment(isTodayWeatherShow).format("YYYY-MM-DD")
      );
      if (tempData?.length > 0) {
        setWeatherValue(tempData[0]);
      } else {
        setWeatherValue({});
        getSpecificDateForecast(
          moment(isTodayWeatherShow).format("YYYY-MM-DD")
        ).then((result) => {
          if (result?.length > 0) {
            setWeatherValue(result[0]);
          } else {
            setNoDataFound(true);
          }
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTodayWeatherShow, tenDaysForecast]);

  const showTableValue = (
    label,
    temp_high,
    temp_low,
    isFirst = false,
    isLast = false
  ) => {
    return (
      <Grid
        className="temp-history-table-cell"
        sx={{
          borderBottom: !isLast ? "1px solid #ccc" : "1px solid transparent",
        }}
      >
        <Typography className="temp-history-table-cell-label">
          {label}
        </Typography>
        <Grid className="temp-history-table-high-low">
          <Grid>
            {isFirst && <Typography sx={{ py: 2 }}>High</Typography>}
            <Typography sx={{ fontWeight: 600 }}>{temp_high}°</Typography>
          </Grid>
          <Grid sx={{ mr: 3 }}>
            {isFirst && <Typography sx={{ py: 2 }}>Low</Typography>}
            <Typography sx={{ textAlign: "end", fontWeight: 600 }}>
              {temp_low}°
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      <Helmet>
        <title>
          {`${moment(isTodayWeatherShow || new Date()).format(
            "DD-MM-YYYY"
          )}'s Forecast` || SEO.DATEWISE_PAGE_TITLE}
        </title>
        <meta name="description" content={SEO.DATEWISE_PAGE_DESCRIPTION} />
        <meta name="keywords" content={SEO.DATEWISE_PAGE_KEYWORDS} />

        <meta property="og:title" content={SEO.DATEWISE_PAGE_TITLE} />
        <meta
          property="og:description"
          content={SEO.DATEWISE_PAGE_DESCRIPTION}
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={CONSTANT.WEATHER_WEB_LINK} />
        <meta property="og:site_name" content={CONSTANT.WEATHER_TIMES} />

        <meta name="twitter:title" content={SEO.DATEWISE_PAGE_TITLE} />
        <meta
          name="twitter:description"
          content={SEO.DATEWISE_PAGE_DESCRIPTION}
        />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <Box className="date-wise-container">
        <Grid className="date-wise-data-date">
          <Tooltip title="Previous Day" placement="top" arrow>
            <KeyboardDoubleArrowLeftIcon
              className="cursor-pointer"
              onClick={(e) => {
                const newDate = moment(isTodayWeatherShow || new Date())
                  .subtract(1, "days")
                  .format("YYYY-MM-DD");
                setNoDataFound(false);
                setIsTodayWeatherShow(newDate);
              }}
            />
          </Tooltip>
          <Typography className="cursor-pointer">
            {moment(isTodayWeatherShow || new Date()).format("dddd, MMM D")}
          </Typography>
          <Tooltip title="Next Day" placement="top" arrow>
            <KeyboardDoubleArrowRightIcon
              className="cursor-pointer"
              onClick={(e) => {
                setNoDataFound(false);
                setIsTodayWeatherShow(
                  moment(isTodayWeatherShow || new Date())
                    .add(1, "days")
                    .format("YYYY-MM-DD")
                );
              }}
            />
          </Tooltip>
        </Grid>
        <Grid className="tabbing-parent-div">
          <Tabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons
            aria-label="visible arrows tabs example"
            sx={{
              [`& .${tabsClasses.scrollButtons}`]: {
                "&.Mui-disabled": { opacity: 0.3 },
              },
            }}
          >
            <Tab label="MORNING" />
            <Tab label="AFTERNOON" />
            <Tab label="EVENING" />
            <Tab label="OVERNIGHT" />
            <Tab label="DAY" />
            <Tab label="NIGHT" />
          </Tabs>
        </Grid>
        {Object.keys(weatherValue)?.length > 0 ? (
          <>
            {currentWeatherShow !== undefined && (
              <Grid>
                <DateWiseWeatherCard
                  timeStatus="Current Weather"
                  date={currentWeatherShow?.last_updated || new Date()}
                  icon={currentWeatherShow?.condition?.icon || ""}
                  temp_c={
                    isFahrenheitValue
                      ? currentWeatherShow?.temp_f
                      : currentWeatherShow?.temp_c || 0
                  }
                  realFill={
                    isFahrenheitValue
                      ? currentWeatherShow?.feelslike_f
                      : currentWeatherShow?.feelslike_c || 0
                  }
                  chance_of_rain={currentWeatherShow?.humidity || 0}
                  isCurrentWeather={true}
                  timeStatusDes={
                    currentWeatherShow?.condition?.text || "Sunny and hot"
                  }
                  labelValueArray={[
                    {
                      label: "Wind",
                      value: `${currentWeatherShow.wind_dir || "NNE"} ${
                        currentWeatherShow.wind_kph || 0
                      } km/h`,
                    },
                    {
                      label: "Air Quality",
                      value: getAirQualityLevel(
                        currentWeatherShow?.air_quality
                      ),
                    },
                    {
                      label: "Max UV Index",
                      value: `${currentWeatherShow.uv || 0}`,
                    },
                    {
                      label: "Wind Gusts",
                      value: `${currentWeatherShow.gust_kph || 0} km/h`,
                    },
                    {
                      label: "Precipitation",
                      value: `${currentWeatherShow.precip_mm || 0} mm`,
                    },

                    {
                      label: "Cloud Cover",
                      value: `${currentWeatherShow.cloud || 0} %`,
                    },

                    {
                      label: "Visibility",
                      value: `${currentWeatherShow.vis_km || 0} km`,
                    },
                    {
                      label: "Pressure",
                      value: `${currentWeatherShow.pressure_in || 0} ps`,
                    },
                    {
                      label: "Pressure Milibar",
                      value: `${currentWeatherShow.pressure_mb || 0} milibar`,
                    },
                  ]}
                />
              </Grid>
            )}
            {value === 0 && (
              <Grid>
                <DateWiseWeatherCard
                  timeStatus="Morning"
                  date={weatherValue?.date || new Date()}
                  icon={weatherValue?.hour[9]?.condition?.icon || ""}
                  temp_c={
                    isFahrenheitValue
                      ? weatherValue?.hour[9]?.temp_f
                      : weatherValue?.hour[9]?.temp_c || 0
                  }
                  realFill={
                    isFahrenheitValue
                      ? weatherValue?.hour[9]?.feelslike_f
                      : weatherValue?.hour[9]?.feelslike_c || 0
                  }
                  chance_of_rain={weatherValue?.hour[9]?.chance_of_rain || 0}
                  timeStatusDes={
                    weatherValue?.hour[9]?.condition?.text || "Sunny and hot"
                  }
                  labelValueArray={[
                    {
                      label: "Wind",
                      value: `${weatherValue?.hour[9]?.wind_dir || "NNE"} ${
                        weatherValue?.hour[9]?.wind_kph || 0
                      } km/h`,
                    },

                    {
                      label: "Probability of Precipitation",
                      value: `${weatherValue?.hour[9]?.precip_in || 0} %`,
                    },
                    {
                      label: "Wind Gusts",
                      value: `${weatherValue?.hour[9]?.gust_kph || 0} km/h`,
                    },
                    {
                      label: "Precipitation",
                      value: `${weatherValue?.hour[9]?.precip_mm || 0} mm`,
                    },
                    {
                      label: "Humidity",
                      value: `${weatherValue?.hour[9]?.humidity || 0} %`,
                    },
                    {
                      label: "Cloud Cover",
                      value: `${weatherValue?.hour[9]?.cloud || 0} %`,
                    },
                    {
                      label: "Dew Point",
                      value: isFahrenheitValue
                        ? `${weatherValue?.hour[9]?.dewpoint_f || 0}° F`
                        : `${weatherValue?.hour[9]?.dewpoint_c || 0}° C`,
                    },
                    {
                      label: "Visibility",
                      value: `${weatherValue?.hour[9]?.vis_km || 0} km`,
                    },
                  ]}
                />
              </Grid>
            )}
            {value === 1 && (
              <Grid>
                <DateWiseWeatherCard
                  timeStatus="AFTERNOON"
                  date={weatherValue?.date || new Date()}
                  icon={weatherValue?.hour[13]?.condition?.icon || ""}
                  temp_c={
                    isFahrenheitValue
                      ? weatherValue?.hour[13]?.temp_f
                      : weatherValue?.hour[13]?.temp_c || 0
                  }
                  realFill={
                    isFahrenheitValue
                      ? weatherValue?.hour[13]?.feelslike_f
                      : weatherValue?.hour[13]?.feelslike_c || 0
                  }
                  chance_of_rain={weatherValue?.hour[13]?.chance_of_rain || 0}
                  timeStatusDes={
                    weatherValue?.hour[13]?.condition?.text || "Sunny and hot"
                  }
                  labelValueArray={[
                    {
                      label: "Wind",
                      value: `${weatherValue?.hour[13]?.wind_dir || "NNE"} ${
                        weatherValue?.hour[13]?.wind_kph || 0
                      } km/h`,
                    },

                    {
                      label: "Probability of Precipitation",
                      value: `${weatherValue?.hour[13]?.precip_in || 0} %`,
                    },
                    {
                      label: "Wind Gusts",
                      value: `${weatherValue?.hour[13]?.gust_kph || 0} km/h`,
                    },
                    {
                      label: "Precipitation",
                      value: `${weatherValue?.hour[13]?.precip_mm || 0} mm`,
                    },
                    {
                      label: "Humidity",
                      value: `${weatherValue?.hour[13]?.humidity || 0} %`,
                    },
                    {
                      label: "Cloud Cover",
                      value: `${weatherValue?.hour[13]?.cloud || 0} %`,
                    },
                    {
                      label: "Dew Point",
                      value: isFahrenheitValue
                        ? `${weatherValue?.hour[13]?.dewpoint_f || 0}° F`
                        : `${weatherValue?.hour[13]?.dewpoint_c || 0}° C`,
                    },
                    {
                      label: "Visibility",
                      value: `${weatherValue?.hour[13]?.vis_km || 0} km`,
                    },
                  ]}
                />
              </Grid>
            )}
            {value === 2 && (
              <Grid>
                <DateWiseWeatherCard
                  timeStatus="EVENING"
                  date={weatherValue?.date || new Date()}
                  icon={weatherValue?.hour[20]?.condition?.icon || ""}
                  temp_c={
                    isFahrenheitValue
                      ? weatherValue?.hour[20]?.temp_f
                      : weatherValue?.hour[20]?.temp_c || 0
                  }
                  realFill={
                    isFahrenheitValue
                      ? weatherValue?.hour[20]?.feelslike_f
                      : weatherValue?.hour[20]?.feelslike_c || 0
                  }
                  chance_of_rain={weatherValue?.hour[20]?.chance_of_rain || 0}
                  timeStatusDes={
                    weatherValue?.hour[20]?.condition?.text || "Sunny and hot"
                  }
                  labelValueArray={[
                    {
                      label: "Wind",
                      value: `${weatherValue?.hour[20]?.wind_dir || "NNE"} ${
                        weatherValue?.hour[20]?.wind_kph || 0
                      } km/h`,
                    },

                    {
                      label: "Probability of Precipitation",
                      value: `${weatherValue?.hour[20]?.precip_in || 0} %`,
                    },
                    {
                      label: "Wind Gusts",
                      value: `${weatherValue?.hour[20]?.gust_kph || 0} km/h`,
                    },
                    {
                      label: "Precipitation",
                      value: `${weatherValue?.hour[20]?.precip_mm || 0} mm`,
                    },
                    {
                      label: "Humidity",
                      value: `${weatherValue?.hour[20]?.humidity || 0} %`,
                    },
                    {
                      label: "Cloud Cover",
                      value: `${weatherValue?.hour[20]?.cloud || 0} %`,
                    },
                    {
                      label: "Dew Point",
                      value: isFahrenheitValue
                        ? `${weatherValue?.hour[20]?.dewpoint_f || 0}° F`
                        : `${weatherValue?.hour[20]?.dewpoint_c || 0}° C`,
                    },
                    {
                      label: "Visibility",
                      value: `${weatherValue?.hour[20]?.vis_km || 0} km`,
                    },
                  ]}
                />
              </Grid>
            )}
            {value === 3 && (
              <Grid>
                <DateWiseWeatherCard
                  timeStatus="OVERNIGHT"
                  date={weatherValue?.date || new Date()}
                  icon={weatherValue?.hour[0]?.condition?.icon || ""}
                  temp_c={
                    isFahrenheitValue
                      ? weatherValue?.hour[0]?.temp_f
                      : weatherValue?.hour[0]?.temp_c || 0
                  }
                  realFill={
                    isFahrenheitValue
                      ? weatherValue?.hour[0]?.feelslike_f
                      : weatherValue?.hour[0]?.feelslike_c || 0
                  }
                  chance_of_rain={weatherValue?.hour[0]?.chance_of_rain || 0}
                  timeStatusDes={
                    weatherValue?.hour[0]?.condition?.text || "Sunny and hot"
                  }
                  labelValueArray={[
                    {
                      label: "Wind",
                      value: `${weatherValue?.hour[0]?.wind_dir || "NNE"} ${
                        weatherValue?.hour[0]?.wind_kph || 0
                      } km/h`,
                    },

                    {
                      label: "Probability of Precipitation",
                      value: `${weatherValue?.hour[0]?.precip_in || 0} %`,
                    },
                    {
                      label: "Wind Gusts",
                      value: `${weatherValue?.hour[0]?.gust_kph || 0} km/h`,
                    },
                    {
                      label: "Precipitation",
                      value: `${weatherValue?.hour[0]?.precip_mm || 0} mm`,
                    },
                    {
                      label: "Humidity",
                      value: `${weatherValue?.hour[0]?.humidity || 0} %`,
                    },
                    {
                      label: "Cloud Cover",
                      value: `${weatherValue?.hour[0]?.cloud || 0} %`,
                    },
                    {
                      label: "Dew Point",
                      value: isFahrenheitValue
                        ? `${weatherValue?.hour[0]?.dewpoint_f || 0}° F`
                        : `${weatherValue?.hour[0]?.dewpoint_c || 0}° C`,
                    },
                    {
                      label: "Visibility",
                      value: `${weatherValue?.hour[0]?.vis_km || 0} km`,
                    },
                  ]}
                />
              </Grid>
            )}
            {value === 4 && (
              <Grid>
                <DateWiseWeatherCard
                  timeStatus="DAY"
                  date={weatherValue?.date || new Date()}
                  icon={weatherValue?.day?.condition?.icon || ""}
                  temp_c={
                    isFahrenheitValue
                      ? weatherValue?.day?.avgtemp_f
                      : weatherValue?.day?.avgtemp_c || 0
                  }
                  realFill={
                    isFahrenheitValue
                      ? weatherValue?.day?.maxtemp_f
                      : weatherValue?.day?.maxtemp_c || 0
                  }
                  chance_of_rain={weatherValue?.day?.daily_chance_of_rain || 0}
                  timeStatusDes={
                    weatherValue?.day?.condition?.text || "Sunny and hot"
                  }
                  labelValueArray={[
                    {
                      label: "Wind",
                      value: `${weatherValue?.day?.maxwind_kph || 0} km/h`,
                    },

                    {
                      label: "Probability of Precipitation",
                      value: `${weatherValue?.day?.totalprecip_in || 0} %`,
                    },
                    {
                      label: "Precipitation",
                      value: `${weatherValue?.day?.totalprecip_mm || 0} mm`,
                    },
                    {
                      label: "Humidity",
                      value: `${weatherValue?.day?.avghumidity || 0} %`,
                    },
                    {
                      label: "Snow",
                      value: `${
                        weatherValue?.day?.daily_chance_of_snow || 0
                      } %`,
                    },

                    {
                      label: "Visibility",
                      value: `${weatherValue?.day?.avgvis_km || 0} km`,
                    },
                  ]}
                />
              </Grid>
            )}
            {value === 5 && (
              <Grid>
                <DateWiseWeatherCard
                  timeStatus="NIGHT"
                  date={weatherValue?.date || new Date()}
                  icon={weatherValue?.hour[5]?.condition?.icon || ""}
                  temp_c={
                    isFahrenheitValue
                      ? weatherValue?.hour[5]?.temp_f
                      : weatherValue?.hour[5]?.temp_c || 0
                  }
                  realFill={
                    isFahrenheitValue
                      ? weatherValue?.hour[5]?.feelslike_f
                      : weatherValue?.hour[5]?.feelslike_c || 0
                  }
                  chance_of_rain={weatherValue?.hour[5]?.chance_of_rain || 0}
                  timeStatusDes={
                    weatherValue?.hour[5]?.condition?.text || "Sunny and hot"
                  }
                  labelValueArray={[
                    {
                      label: "Wind",
                      value: `${weatherValue?.hour[5]?.wind_dir || "NNE"} ${
                        weatherValue?.hour[5]?.wind_kph || 0
                      } km/h`,
                    },

                    {
                      label: "Probability of Precipitation",
                      value: `${weatherValue?.hour[5]?.precip_in || 0} %`,
                    },
                    {
                      label: "Wind Gusts",
                      value: `${weatherValue?.hour[5]?.gust_kph || 0} km/h`,
                    },
                    {
                      label: "Precipitation",
                      value: `${weatherValue?.hour[5]?.precip_mm || 0} mm`,
                    },
                    {
                      label: "Humidity",
                      value: `${weatherValue?.hour[5]?.humidity || 0} %`,
                    },
                    {
                      label: "Cloud Cover",
                      value: `${weatherValue?.hour[5]?.cloud || 0} %`,
                    },
                    {
                      label: "Dew Point",
                      value: isFahrenheitValue
                        ? `${weatherValue?.hour[5]?.dewpoint_f || 0}° F`
                        : `${weatherValue?.hour[5]?.dewpoint_c || 0}° C`,
                    },
                    {
                      label: "Visibility",
                      value: `${weatherValue?.hour[5]?.vis_km || 0} km`,
                    },
                  ]}
                />
              </Grid>
            )}
            <Grid className="moon-sun-weather-container">
              <Grid className="moon-sun-weather-container-top">
                <Typography variant="h2">SUN & MOON</Typography>
                <Typography>
                  {moment(weatherValue?.date || new Date()).format("M/D")}
                </Typography>
              </Grid>
              <Divider className="divider-black" />
              <Grid className="moon-sun-weather-container-bottom">
                <Grid className="display-flex-all-center moon-sun-weather-container-bottom-1">
                  <Grid className="moon-sun-weather-container-bottom-1-1 display-flex-all-center">
                    <img src={iconSVG} alt="SunIcon" height="40px" />
                    <Typography>
                      {differenceBetweenSunsetSunrise(
                        weatherValue?.astro?.sunrise,
                        weatherValue?.astro?.sunset
                      )}
                    </Typography>
                  </Grid>
                  <Grid className="moon-sun-weather-container-bottom-1-2 display-flex-all-center">
                    <Divider
                      className="divider-black"
                      orientation="vertical"
                      flexItem
                    />

                    <Grid className="moon-sun-weather-container-bottom-1-2-1">
                      <Grid className="moon-sun-weather-container-bottom-1-2 display-flex-all-center">
                        <Typography className="p-1">Rise</Typography>
                        <Typography className="p-2">
                          {weatherValue?.astro?.sunrise || "6:12 AM"}
                        </Typography>
                      </Grid>
                      <Grid className="moon-sun-weather-container-bottom-1-2 display-flex-all-center">
                        <Typography className="p-1">Set</Typography>
                        <Typography className="p-2">
                          {weatherValue?.astro?.sunset || "7:02 AM"}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Divider className="divider-black" />
                <Grid className="display-flex-all-center moon-sun-weather-container-bottom-1">
                  <Grid className="moon-sun-weather-container-bottom-1-1 display-flex-all-center">
                    <img
                      src={moonPhaseIcon}
                      alt="MoonIconPhase"
                      height="40px"
                    />
                    <Typography>{weatherValue?.astro?.moon_phase}</Typography>
                  </Grid>

                  <Grid className="moon-sun-weather-container-bottom-1-2 display-flex-all-center">
                    <Divider
                      className="divider-black"
                      orientation="vertical"
                      flexItem
                    />
                    <Grid className="moon-sun-weather-container-bottom-1-2-1">
                      <Grid className="moon-sun-weather-container-bottom-1-2 display-flex-all-center">
                        <Typography className="p-1">Rise</Typography>
                        <Typography className="p-2">
                          {" "}
                          {weatherValue?.astro?.moonrise || "8:0 PM"}
                        </Typography>
                      </Grid>
                      <Grid className="moon-sun-weather-container-bottom-1-2 display-flex-all-center">
                        <Typography className="p-1">Set</Typography>
                        <Typography className="p-2">
                          {weatherValue?.astro?.moonset || "7:29 AM"}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid className="moon-sun-weather-container">
              <Grid className="moon-sun-weather-container-top">
                <Typography variant="h2">TEMPERATURE HISTORY</Typography>
                <Typography>
                  {moment(weatherValue?.date || new Date()).format("M/D")}
                </Typography>
              </Grid>
              <Grid sx={{ mx: 2, py: 4 }}>
                <Divider className="divider-black" />
                {showTableValue(
                  "Forecast",
                  isFahrenheitValue
                    ? weatherValue?.day?.maxtemp_f
                    : weatherValue?.day?.maxtemp_c,
                  isFahrenheitValue
                    ? weatherValue?.day?.mintemp_f
                    : weatherValue?.day?.mintemp_c,
                  true
                )}
                {showTableValue(
                  "Average",
                  isFahrenheitValue
                    ? weatherValue?.day?.avgtemp_f
                    : weatherValue?.day?.avgtemp_c,
                  0
                )}
                {showTableValue("Last Year", 0, 0, false, true)}
              </Grid>
            </Grid>
          </>
        ) : noDataFound ? (
          <Grid>
            <DateWiseWeatherCard
              timeStatus="No Data Found"
              date={isTodayWeatherShow || new Date()}
              isNotDataFound={true}
            />
          </Grid>
        ) : (
          <Grid>
            <DateWiseWeatherCard
              timeStatus="Loading..."
              date={weatherValue?.date || new Date()}
              isLoader={true}
            />
          </Grid>
        )}

        <Grid
          className="previous-btn-div"
          onClick={() => window?.history.back()}
        >
          <KeyboardBackspaceIcon />
          <Typography>Previous</Typography>
          <Grid />
        </Grid>
        <Grid sx={{ mb: 2 }}>
          <HourlyLinkLabel label="Further Ahead" />
          <HourlyLinkLabel label="Hourly" isLink={true} />
          <HourlyLinkLabel label="Daily" isLink={true} />
          <HourlyLinkLabel label="Monthly" isLink={true} />
        </Grid>
      </Box>
    </>
  );
}

export default DateWiseWeather;
