import React, { useContext, useEffect } from "react";
import "./HourlyWeather.scss";
import { Helmet } from "react-helmet";
import { CONSTANT, SEO } from "../../../Utils/Constant";
import { Grid, Typography } from "@mui/material";
import HourlyWeatherCard from "../../Common/HourlyWeather/Card/HourlyWeatherCard";
import HourlyLinkLabel from "../../Common/HourlyWeather/HourlyLinkLabel/HourlyLinkLabel";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { DataContext } from "../../../Context/DataContext";
import {
  getAirQualityLevel,
  getNext7Days,
} from "../../../Utils/CommonFunction";
import moment from "moment";
import Loader from "../../Common/Loader/Loader";
function HourlyWeather() {
  const {
    linkClicked,
    setTabbingValue,
    tenDaysForecast,
    dayIndex,
    isFahrenheitValue,
    setShowWeatherTabbing,
    isLoading,
  } = useContext(DataContext);

  useEffect(() => {
    setTabbingValue(1);
    setShowWeatherTabbing(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Helmet>
        <title>{SEO.HOURLY_WEATHER_TITLE}</title>
        <meta name="description" content={SEO.HOURLY_WEATHER_DESCRIPTION} />
        <meta name="keywords" content={SEO.HOURLY_WEATHER_KEYWORDS} />

        <meta property="og:title" content={SEO.HOURLY_WEATHER_TITLE} />
        <meta
          property="og:description"
          content={SEO.HOURLY_WEATHER_DESCRIPTION}
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={CONSTANT.WEATHER_WEB_LINK} />
        <meta property="og:site_name" content={CONSTANT.WEATHER_TIMES} />

        <meta name="twitter:title" content={SEO.HOURLY_WEATHER_TITLE} />
        <meta
          name="twitter:description"
          content={SEO.HOURLY_WEATHER_DESCRIPTION}
        />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      {isLoading ? (
        <Loader />
      ) : (
        <Grid container>
          {tenDaysForecast?.forecast?.forecastday?.length > 0 ? (
            <Grid item xs={12}>
              <Typography>
                {moment(
                  tenDaysForecast?.forecast?.forecastday[dayIndex]?.date
                ).format("dddd")}
              </Typography>
              {tenDaysForecast?.forecast?.forecastday[dayIndex]?.hour.map(
                (item, index) => (
                  <Grid key={index}>
                    <HourlyWeatherCard
                      weatherStatus={item?.condition?.text}
                      hourInterval={item?.time}
                      imgIcon={item?.condition?.icon}
                      titleTemp={
                        isFahrenheitValue ? item?.temp_f : item?.temp_c
                      }
                      titleTagLine={item?.condition?.text}
                      rainPossibility={item?.chance_of_rain}
                      labelValueArray={[
                        {
                          label: "RealFeel Shade™",
                          value: isFahrenheitValue
                            ? `${item?.feelslike_f} °F`
                            : `${item?.feelslike_c} °C` || "36°",
                        },
                        {
                          label: "Air Quality",
                          value: getAirQualityLevel(item?.air_quality),
                        },
                        {
                          label: "Wind",
                          value: `${item?.wind_dir || "NNE 7"} ${
                            item?.wind_kph || 0
                          }km/h`,
                        },
                        {
                          label: "Max UV Index",
                          value: `${item?.uv || 0} Moderate`,
                        },
                        {
                          label: "Wind Gusts",
                          value: `${item?.gust_kph || 0} km/h`,
                        },
                        {
                          label: "Humidity",
                          value: `${item?.humidity}%` || "0%",
                        },

                        {
                          label: "Dew Point",
                          value: isFahrenheitValue
                            ? `${item?.dewpoint_f || 0} °F`
                            : `${item?.dewpoint_c || 0} °C`,
                        },
                        {
                          label: "Cloud Cover",
                          value: `${item?.cloud}%` || "0%",
                        },
                        {
                          label: "Visibility",
                          value: `${item?.vis_km} km` || "0 km",
                        },
                        {
                          label: "Chance for Snow ",
                          value: `${item?.chance_of_snow} %` || "0 %",
                        },
                        {
                          label: "Heat Index",
                          value:
                            (isFahrenheitValue
                              ? `${item?.heatindex_f} °F`
                              : `${item?.heatindex_c} °C`) || "0 °C",
                        },
                        {
                          label: "Wind Chill",
                          value:
                            (isFahrenheitValue
                              ? `${item?.windchill_f} °F`
                              : `${item?.windchill_c} °C`) || "0 °C",
                        },
                        {
                          label: "Pressure",
                          value:
                            `${item?.pressure_in} millibars` || "0 millibars",
                        },
                      ]}
                    />
                  </Grid>
                )
              )}{" "}
            </Grid>
          ) : (
            <Grid item xs={12} sx={{ p: 4, textAlign: "center" }}>
              <Loader />
            </Grid>
          )}
          {linkClicked && (
            <Grid
              item
              xs={12}
              className="previous-btn-div"
              onClick={() => window?.history.back()}
            >
              <KeyboardBackspaceIcon />
              <Typography>Previous</Typography>
              <Grid />
            </Grid>
          )}
          <Grid item xs={12} sx={{ mb: 2 }}>
            <HourlyLinkLabel label="Further Ahead" />
            {getNext7Days().map((day, index) => (
              <Grid key={index}>
                <HourlyLinkLabel
                  label={day?.dayName}
                  count={day?.count}
                  isLink={true}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
      )}
    </>
  );
}

export default HourlyWeather;
