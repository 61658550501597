import React from "react";
import { Box, Grid } from "@mui/material";
import BlogHomeCommonCard from "./BlogHomeCommonCard";
import { Helmet } from "react-helmet";
import { CONSTANT, SEO } from "../../../Utils/Constant";

function HomePage() {
  return (
    <>
      {" "}
      <Helmet>
        <title>{SEO.HOME_PAGE_TITLE}</title>
        <meta name="description" content={SEO.HOME_PAGE_DESCRIPTION} />
        <meta name="keywords" content={SEO.HOME_PAGE_KEYWORDS} />

        <meta property="og:title" content={SEO.HOME_PAGE_TITLE} />
        <meta property="og:description" content={SEO.HOME_PAGE_DESCRIPTION} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={CONSTANT.WEATHER_WEB_LINK} />
        <meta property="og:site_name" content={CONSTANT.WEATHER_TIMES} />

        <meta name="twitter:title" content={SEO.HOME_PAGE_TITLE} />
        <meta name="twitter:description" content={SEO.HOME_PAGE_DESCRIPTION} />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <Box>
        <Grid>
          <BlogHomeCommonCard />
        </Grid>
      </Box>
    </>
  );
}

export default HomePage;
