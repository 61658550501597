import {
  Divider,
  Grid,
  Tab,
  Tabs,
  tabsClasses,
  Typography,
} from "@mui/material";
import moment from "moment";
import React, { useContext, useEffect } from "react";
import todaySunIcon from "../../../Assets/Icon/TodaySun.svg";
import todayMoonIcon from "../../../Assets/Icon/MoonHourly.svg";
import rightArrow from "../../../Assets/Icon/RightPointArrow.svg";
import moonPhaseIcon from "../../../Assets/Icon/MoonPhase.svg";
import airQualityIcon from "../../../Assets/Icon/AirQualityIcon.svg";
import "./TodayWeather.scss";
import rainDropIcon from "../../../Assets/Icon/RainDrop.svg";
import { CONSTANT, SEO } from "../../../Utils/Constant";
import { Helmet } from "react-helmet";
import { DataContext } from "../../../Context/DataContext";
import {
  assessAirQuality,
  differenceBetweenSunsetSunrise,
  getAirQualityLevel,
} from "../../../Utils/CommonFunction";
import Loader from "../../Common/Loader/Loader";
import { useNavigate } from "react-router";
import windMillTop from "../../../Assets/Icon/WindMillSvgAnimate.svg";
function TodayWeather() {
  const {
    setTabbingValue,
    cityForecastWeather,
    tenDaysForecast,
    isLoading,
    setIsTodayWeatherShow,
    setCurrentWeatherShow,
    isFahrenheitValue,
    setShowWeatherTabbing,
  } = useContext(DataContext);

  useEffect(() => {
    setTabbingValue(0);
    setShowWeatherTabbing(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const navigate = useNavigate();
  const navigateToSpecificDate = (date) => {
    setIsTodayWeatherShow(moment(date).format("YYYY-MM-DD"));
    navigate(`/mausam-specified-date`);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      <Helmet>
        <title>{SEO.TODAY_WEATHER_TITLE}</title>
        <meta name="description" content={SEO.TODAY_WEATHER_DESCRIPTION} />
        <meta name="keywords" content={SEO.TODAY_WEATHER_KEYWORDS} />

        <meta property="og:title" content={SEO.TODAY_WEATHER_TITLE} />
        <meta
          property="og:description"
          content={SEO.TODAY_WEATHER_DESCRIPTION}
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={CONSTANT.WEATHER_WEB_LINK} />
        <meta property="og:site_name" content={CONSTANT.WEATHER_TIMES} />

        <meta name="twitter:title" content={SEO.TODAY_WEATHER_TITLE} />
        <meta
          name="twitter:description"
          content={SEO.TODAY_WEATHER_DESCRIPTION}
        />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>

      {isLoading ? (
        <Loader />
      ) : (
        <Grid>
          <Grid
            className="today-weather-container"
            onClick={() => navigateToSpecificDate(new Date())}
          >
            <Grid className="today-weather-container-top">
              <Typography variant="h2">TODAY'S WEATHER</Typography>
              <Typography>
                {moment(
                  cityForecastWeather?.forecast?.forecastday[0]?.date ||
                    new Date()
                ).format("ddd, MMM DD")}
              </Typography>
            </Grid>
            <Divider className="divider-black" />
            <Grid className="today-weather-container-bottom">
              <Grid>
                <img src={todaySunIcon} alt="sun" height="22px" />
                <Typography>
                  Very warm with plenty of sunshine{" "}
                  <b>
                    Hi:
                    {isFahrenheitValue
                      ? cityForecastWeather?.forecast?.forecastday[0]?.day
                          ?.maxtemp_f
                      : cityForecastWeather?.forecast?.forecastday[0]?.day
                          ?.maxtemp_c || "39"}
                    °
                  </b>
                </Typography>
              </Grid>
              <Grid>
                <img src={todayMoonIcon} alt="TodayMoon" height="22px" />
                <Typography>
                  Tonight: Clear{" "}
                  <b>
                    Low:
                    {isFahrenheitValue
                      ? cityForecastWeather?.forecast?.forecastday[0]?.day
                          ?.mintemp_f
                      : cityForecastWeather?.forecast?.forecastday[0]?.day
                          ?.mintemp_c || "25"}
                    °
                  </b>
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            className="current-weather-container"
            onClick={() => {
              setCurrentWeatherShow(cityForecastWeather?.current);
              navigateToSpecificDate(new Date());
            }}
          >
            <Grid className="current-weather-container-top">
              <Typography variant="h2">CURRENT WEATHER</Typography>
              <Typography>
                {moment(
                  cityForecastWeather?.current?.last_updated || new Date()
                ).format("h:mm A")}
              </Typography>
            </Grid>
            <Divider className="divider-black" />
            <Grid className="current-weather-container-bottom">
              <Grid container className="current-weather-container-bottom-1">
                <Grid
                  item
                  xs={12}
                  sm={12}
                  lg={6}
                  className="current-weather-container-bottom-1-1"
                >
                  <img
                    src={
                      cityForecastWeather?.current?.condition?.icon ||
                      CONSTANT.commonSunIcon
                    }
                    alt={cityForecastWeather?.current?.condition?.text || "sun"}
                    height="88px"
                  />
                  <Grid>
                    <Typography variant="h3">
                      {isFahrenheitValue
                        ? cityForecastWeather?.current?.temp_f
                        : cityForecastWeather?.current?.temp_c || "39"}
                      °
                      <span className="after-temp">
                        {isFahrenheitValue ? "F" : "C"}
                      </span>
                    </Typography>
                    <Typography>
                      RealFeel®{" "}
                      {isFahrenheitValue
                        ? `${cityForecastWeather?.current?.feelslike_f || 0}°F`
                        : `${cityForecastWeather?.current?.feelslike_c || 0}°C`}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  lg={6}
                  className="current-weather-bottom-right"
                >
                  <Grid className="current-weather-bottom-right-content">
                    <Typography className="current-weather-label">
                      RealFeel Shade™
                    </Typography>
                    <Typography className="current-weather-value">
                      {isFahrenheitValue
                        ? `${cityForecastWeather?.current?.temp_f || 0} °F`
                        : `${cityForecastWeather?.current?.temp_c || 0} °C`}
                    </Typography>
                  </Grid>
                  <Divider />
                  <Grid className="current-weather-bottom-right-content">
                    <Typography className="current-weather-label">
                      Wind
                    </Typography>
                    <Typography className="current-weather-value">
                      {cityForecastWeather?.current?.wind_dir || "N"}{" "}
                      {cityForecastWeather?.current?.wind_kph || "8"} km/h
                    </Typography>
                  </Grid>
                  <Divider />
                  <Grid className="current-weather-bottom-right-content">
                    <Typography className="current-weather-label">
                      Wind Gusts
                    </Typography>
                    <Typography className="current-weather-value">
                      {cityForecastWeather?.current?.gust_kph || "14"} km/h
                    </Typography>
                  </Grid>
                  <Divider />
                  <Grid className="current-weather-bottom-right-content">
                    <Typography className="current-weather-label">
                      Air Quality
                    </Typography>
                    <Typography
                      className="current-weather-value"
                      sx={{
                        color:
                          getAirQualityLevel(
                            cityForecastWeather?.current?.air_quality
                          ) === "Poor"
                            ? "orange"
                            : getAirQualityLevel(
                                cityForecastWeather?.current?.air_quality
                              ) === "Good"
                            ? "green"
                            : "red",
                      }}
                    >
                      {getAirQualityLevel(
                        cityForecastWeather?.current?.air_quality
                      )}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  className="current-weather-bottom-content"
                >
                  <Typography className="current-weather-status">
                    {cityForecastWeather?.current?.condition?.text || "Sunny"}
                  </Typography>
                  <Typography className="current-weather-more-detail">
                    MORE DETAILS{"  "}
                    <img src={rightArrow} alt="rightArrow" height="7px" />
                  </Typography>
                  <Grid className="wind-mill-div">
                    <img
                      src={windMillTop}
                      alt="wind-mill-top"
                      className="wind-mill-top-1-1"
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid className="hourly-weather-container">
            <Grid className="hourly-weather-container-top">
              <Typography variant="h2">HOURLY WEATHER</Typography>
            </Grid>
            <Divider className="divider-black" />
            <Grid
              className="hourly-weather-container-bottom"
              sx={{
                flexGrow: 1,
                bgcolor: "background.paper",
              }}
            >
              <Tabs
                variant="scrollable"
                scrollButtons
                aria-label="visible arrows tabs example"
                sx={{
                  [`& .${tabsClasses.scrollButtons}`]: {
                    "&.Mui-disabled": { opacity: 0.3 },
                  },
                }}
              >
                {cityForecastWeather?.forecast?.forecastday[0]?.hour.map(
                  (item, index) => (
                    <Tab
                      className="tabbing-tab-hourly"
                      key={index}
                      onClick={() => {
                        navigate("/hourly-mausam");
                        window.scrollTo({
                          top: 0,
                          behavior: "smooth",
                        });
                      }}
                      label={
                        <Grid className="tab-title-label">
                          <Typography className="tab-title-time">
                            {moment(item?.time, "YYYY-MM-DD HH:mm").format(
                              "h A"
                            )}
                          </Typography>
                          <img
                            src={item?.condition?.icon}
                            alt={item?.condition?.text}
                            height="40px"
                          />
                          <Typography className="tab-title-temp">
                            {isFahrenheitValue ? item?.temp_f : item?.temp_c}°
                          </Typography>
                          <Typography className="tab-title-rain-possibility">
                            <img
                              src={rainDropIcon}
                              alt="rainDrop"
                              height="19px"
                            />
                            {item?.chance_of_rain}%
                          </Typography>
                        </Grid>
                      }
                    />
                  )
                )}
              </Tabs>
            </Grid>
          </Grid>

          <Grid className="ten-day-weather-container">
            <Grid className="ten-day-weather-container-top">
              <Typography variant="h2">10-DAY WEATHER FORECAST</Typography>
            </Grid>
            <Divider className="divider-black" />
            {isLoading ? (
              <Grid sx={{ p: 8 }}>
                <Loader />
              </Grid>
            ) : (
              <Grid className="ten-day-weather-container-bottom">
                {tenDaysForecast?.forecast?.forecastday.map((item, index) => (
                  <>
                    <Grid
                      container
                      key={index}
                      className="ten-day-weather-container-bottom-container "
                      onClick={() => navigateToSpecificDate(item?.date)}
                    >
                      <Grid
                        item
                        xs={11}
                        spacing={1}
                        className="ten-day-weather-container-bottom-container-div-one"
                      >
                        <Grid
                          container
                          sx={{
                            gap: { xs: "14px" },
                            justifyContent: {
                              xs: "space-between",
                              md: "flex-start",
                            },
                          }}
                        >
                          <Grid
                            item
                            className="ten-day-weather-container-bottom-container-div-two"
                          >
                            <Typography className="p-1">
                              {moment(item?.date).format("ddd")}
                            </Typography>
                            <Typography className="p-2">
                              {moment(item?.date).format("M/D")}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            className="display-flex-all-center ten-day-weather-container-bottom-container-div-three"
                          >
                            <img
                              src={item?.day?.condition?.icon}
                              alt={item?.day?.condition?.text}
                              height="42px"
                            />
                            <Typography variant="h3">
                              {isFahrenheitValue
                                ? item?.day?.maxtemp_f
                                : item?.day?.maxtemp_c}
                              °
                              <span>
                                {isFahrenheitValue
                                  ? item?.day?.mintemp_f
                                  : item?.day?.mintemp_c}
                                °
                              </span>
                            </Typography>
                            <Typography></Typography>
                          </Grid>
                          <Grid
                            item
                            className="ten-day-weather-container-bottom-container-div-four"
                          >
                            <Typography className="p-1">
                              {item?.astro?.moon_phase}
                            </Typography>
                            <Typography className="p-2">
                              <img
                                src={item?.day?.condition?.icon}
                                alt={item?.day?.condition?.text}
                                height="22px"
                              />
                              {item?.day?.condition?.text}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        xs={1}
                        spacing={1}
                        className="ten-day-weather-container-bottom-container-div-five"
                      >
                        <Typography className="tab-title-rain-possibility">
                          <img
                            src={rainDropIcon}
                            alt="rainDrop"
                            height="12px"
                          />
                          {item?.day?.daily_chance_of_rain}%
                        </Typography>
                      </Grid>
                    </Grid>
                    <Divider className="divider-black" />
                  </>
                ))}
              </Grid>
            )}
          </Grid>

          <Grid className="moon-sun-weather-container">
            <Grid className="moon-sun-weather-container-top">
              <Typography variant="h2">SUN & MOON</Typography>
            </Grid>
            <Divider className="divider-black" />
            <Grid className="moon-sun-weather-container-bottom">
              <Grid className="display-flex-all-center moon-sun-weather-container-bottom-1">
                <Grid className="moon-sun-weather-container-bottom-1-1 display-flex-all-center">
                  <img src={todaySunIcon} alt="SunIcon" height="40px" />
                  <Typography>
                    {differenceBetweenSunsetSunrise(
                      cityForecastWeather?.forecast?.forecastday[0]?.astro
                        ?.sunrise,
                      cityForecastWeather?.forecast?.forecastday[0]?.astro
                        ?.sunset
                    )}
                  </Typography>
                </Grid>
                <Grid className="moon-sun-weather-container-bottom-1-2 display-flex-all-center">
                  <Divider
                    className="divider-black"
                    orientation="vertical"
                    flexItem
                  />

                  <Grid className="moon-sun-weather-container-bottom-1-2-1">
                    <Grid className="moon-sun-weather-container-bottom-1-2 display-flex-all-center">
                      <Typography className="p-1">Rise</Typography>
                      <Typography className="p-2">
                        {cityForecastWeather?.forecast?.forecastday[0]?.astro
                          ?.sunrise || "6:12 AM"}
                      </Typography>
                    </Grid>
                    <Grid className="moon-sun-weather-container-bottom-1-2 display-flex-all-center">
                      <Typography className="p-1">Set</Typography>
                      <Typography className="p-2">
                        {cityForecastWeather?.forecast?.forecastday[0]?.astro
                          ?.sunset || "7:02 AM"}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Divider className="divider-black" />
              <Grid className="display-flex-all-center moon-sun-weather-container-bottom-1">
                <Grid className="moon-sun-weather-container-bottom-1-1 display-flex-all-center">
                  <img src={moonPhaseIcon} alt="MoonIconPhase" height="40px" />
                  <Typography>
                    {
                      cityForecastWeather?.forecast?.forecastday[0]?.astro
                        ?.moon_phase
                    }
                  </Typography>
                </Grid>

                <Grid className="moon-sun-weather-container-bottom-1-2 display-flex-all-center">
                  <Divider
                    className="divider-black"
                    orientation="vertical"
                    flexItem
                  />
                  <Grid className="moon-sun-weather-container-bottom-1-2-1">
                    <Grid className="moon-sun-weather-container-bottom-1-2 display-flex-all-center">
                      <Typography className="p-1">Rise</Typography>
                      <Typography className="p-2">
                        {" "}
                        {cityForecastWeather?.forecast?.forecastday[0]?.astro
                          ?.moonrise || "8:23 PM"}
                      </Typography>
                    </Grid>
                    <Grid className="moon-sun-weather-container-bottom-1-2 display-flex-all-center">
                      <Typography className="p-1">Set</Typography>
                      <Typography className="p-2">
                        {cityForecastWeather?.forecast?.forecastday[0]?.astro
                          ?.moonset || "7:29 AM"}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid className="air-quality-container">
            <Grid className="air-quality-container-top">
              <Typography variant="h2">AIR QUALITY</Typography>
              <Typography>See More</Typography>
            </Grid>
            <Divider className="divider-black" />
            <Grid className="air-quality-container-bottom">
              <Grid className="display-flex-all-center bottom-content">
                <Grid className="display-flex-all-center gap-5">
                  <img src={airQualityIcon} alt="air-quality" height="25px" />
                  <Typography>Air Quality</Typography>
                </Grid>
                <Typography
                  className="air-quality-status"
                  sx={{
                    borderColor:
                      getAirQualityLevel(
                        cityForecastWeather?.current?.air_quality
                      ) === "Good"
                        ? "#008000 !important"
                        : "#fdb80d !important",
                  }}
                >
                  {" "}
                  {getAirQualityLevel(
                    cityForecastWeather?.current?.air_quality
                  )}
                </Typography>
              </Grid>
              <Typography className="air-quality-desc">
                {assessAirQuality(cityForecastWeather?.current?.air_quality)}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
}

export default TodayWeather;
