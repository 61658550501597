import { Grid, Typography } from "@mui/material";
import React, { useContext } from "react";
import "./CommonCssMonthly.scss";
import moment from "moment";
import { useNavigate } from "react-router";
import { DataContext } from "../../../Context/DataContext";

function CalenderContent(props) {
  const { date, temp1, temp2, imgIcon } = props;
  const { setIsTodayWeatherShow } = useContext(DataContext);
  const navigate = useNavigate();
  let presentDate =
    moment(new Date()).format("YYYY-MM-DD") === date ? true : false;

  const navigateToSpecificDate = () => {
    setIsTodayWeatherShow(moment(date).format("YYYY-MM-DD"));
    navigate(`/mausam-specified-date`);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <>
      <Grid
        items
        xs={1.7}
        className="table-date-container"
        sx={{
          opacity: !imgIcon ? 0.5 : 1,
          cursor: "pointer",
        }}
        onClick={(e) => navigateToSpecificDate()}
      >
        <Typography
          className={
            presentDate
              ? "present-class"
              : imgIcon
              ? "future-date-class"
              : "past-date-class"
          }
          sx={{ my: 1 }}
        >
          {moment(date).format("DD")}
        </Typography>
        <img
          src={imgIcon}
          alt="weatherStatuIcon"
          height="40px"
          style={{
            margin: "8px",
            visibility: imgIcon ? "visible" : "hidden",
          }}
        />
        <Typography>{temp1}°</Typography>
        <Typography className="temp-2">{temp2}°</Typography>
      </Grid>
    </>
  );
}

export default CalenderContent;
