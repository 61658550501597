import React from "react";
import { Grid, Typography } from "@mui/material";
import { Helmet } from "react-helmet";
import { CONSTANT, SEO } from "../../../Utils/Constant";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";

function Disclaimer() {
  return (
    <>
      <Helmet>
        <title>{SEO.DISCLAIMER_TITLE}</title>
        <meta name="description" content={SEO.DISCLAIMER_DESCRIPTION} />
        <meta name="keywords" content={SEO.DISCLAIMER_KEYWORDS} />

        <meta property="og:title" content={SEO.DISCLAIMER_TITLE} />
        <meta property="og:description" content={SEO.DISCLAIMER_DESCRIPTION} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={CONSTANT.CALCULATOR_TIMES_WEB_LINK} />
        <meta property="og:site_name" content={CONSTANT.CALCULATOR_TIMES} />

        <meta name="twitter:title" content={SEO.DISCLAIMER_TITLE} />
        <meta name="twitter:description" content={SEO.DISCLAIMER_DESCRIPTION} />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <Grid sx={{ my: 2 }}>
        <Grid>
          <Typography variant="h5" className="back-btn">
            <ArrowBackOutlinedIcon
              sx={{ cursor: "pointer" }}
              onClick={() => window.history.back()}
            />
            Disclaimer
          </Typography>
        </Grid>
        <Grid className="privacy-policy">
          <Typography variant="h2">Disclaimer page</Typography>
        </Grid>
      </Grid>
    </>
  );
}

export default Disclaimer;
