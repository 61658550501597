import React, { useContext, useEffect } from "react";
import { DataContext } from "../../../Context/DataContext";
import { Helmet } from "react-helmet";
import { CONSTANT, SEO } from "../../../Utils/Constant";
import { Grid, Typography } from "@mui/material";
import dayjs from "dayjs";
import "./ContactUs.scss";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";

function TermsOfService() {
  const { setIsPrivacyPage, setShowWeatherTabbing } = useContext(DataContext);
  useEffect(() => {
    setIsPrivacyPage(true);
    setShowWeatherTabbing(false);
  });

  return (
    <>
      <Helmet>
        <title>{SEO.TERMS_SERVICE_TITLE}</title>
        <meta name="description" content={SEO.TERMS_SERVICE_DESCRIPTION} />
        <meta name="keywords" content={SEO.TERMS_SERVICE_KEYWORDS} />

        <meta property="og:title" content={SEO.TERMS_SERVICE_TITLE} />
        <meta
          property="og:description"
          content={SEO.TERMS_SERVICE_DESCRIPTION}
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={CONSTANT.WEATHER_WEB_LINK} />
        <meta property="og:site_name" content={CONSTANT.WEATHER_TIMES} />

        <meta name="twitter:title" content={SEO.TERMS_SERVICE_TITLE} />
        <meta
          name="twitter:description"
          content={SEO.TERMS_SERVICE_DESCRIPTION}
        />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <Grid sx={{ my: 2 }}>
        <Grid>
          <Typography variant="h5" className="back-btn">
            <ArrowBackOutlinedIcon
              sx={{ cursor: "pointer" }}
              onClick={() => window.history.back()}
            />
            Terms of Use
          </Typography>
        </Grid>
        <Grid className="privacy-policy">
          <Typography variant="h2">Acceptance of Terms</Typography>
          <Typography>
            Mausam provides this website at{" "}
            <a href="/">
              <b>mausam.net</b>
            </a>{" "}
            for use by consumers and businesses. By accessing or using the
            Website in any manner, you agree to comply with these Terms of
            Service. If you disagree with any term or condition of these Terms
            of Service or any guideline of the Website, your sole option is to
            cease using the Website immediately.
          </Typography>
          <Typography variant="h2">Changes to the Terms of Use</Typography>
          <Typography>
            We reserve the right, at our sole discretion, to change, modify, or
            alter the Terms of Service at any time. Such changes shall become
            effective immediately upon the posting thereof. The most current
            version of the Terms of Service will be linked from the footer of
            the Website. You must review the Terms of Service on a regular basis
            to keep yourself apprised of any changes.
          </Typography>
          <Typography variant="h2">Conduct</Typography>
          <Typography>
            You agree not to post any text, files, images, video, audio, or
            other materials ("Content") or use the Website in any way that:
            <ul>
              <li>
                Infringes any patent, trademark, trade secret, copyright, or
                other proprietary rights of any party;
              </li>
              <li>violates any state, federal, or other law;</li>
              <li>threatens, harasses, or is libelous;</li>
              <li>
                contains self-benefiting advertising or marketing in public
                areas of the Website that have not been paid for and are not
                designated for the addition of promotional content;
              </li>
              <li>
                produces Software viruses or code harmful to other computers;
              </li>
              <li>disrupts the normal dialogue of users of the Website;</li>
              <li>employs misleading or false information;</li>
              <li>
                uses forged headers or other items to manipulate identifiers in
                order to disguise the origin of Content.
              </li>
            </ul>
            <br />
            You agree not to decompile or reverse engineer or otherwise attempt
            to discover any source code contained in the Website.
            <br />
            Unless you receive explicit permission, you agree not to reproduce,
            duplicate, copy, sell, resell, or exploit for any commercial
            purposes, any aspect of the Website. Any business that registers
            with a listing or purchases advertising or any other service
            ("Customer") has the right and permission to manage their listing or
            purchased advertising or other services to their commercial benefit
            so long as the codes of conduct above are not violated.
          </Typography>
          <Typography variant="h2">Privacy</Typography>
          <Typography>
            Please review our Privacy Policy for details on Mausam's collection,
            disclosure, and use of personally identifiable information and
            privacy matters. Use of the Website is based on your agreement to
            the Privacy Policy and Terms of Service.
          </Typography>
          <Typography variant="h2">Content</Typography>
          <Typography>
            This Website and some of the Content on this Website contain links
            to other resources on the Internet. Such links are provided as aids
            to assist you in identifying and locating other Internet resources
            that may be of interest to you, and are not intended to state or
            imply that Mausam sponsors, endorses, is affiliated or associated
            with, or is legally authorized to use any trade name, registered
            trademark, logo, legal or official seal, or copyrighted symbol that
            may be reflected in said links. Website that you believe to be in
            error, please contact us with the specifics and we will investigate
            the matter and where we are allowed by our data source, we will
            update the information.
          </Typography>
          <Typography variant="h2">Limitations and Termination</Typography>
          <Typography>
            Mausam may create limitations on your use of the Website including,
            but not limited to, the number of times you may access the Website.
            Limitations can include full termination of your access to the
            Website and you agree that Mausam has the right at any time, in its
            sole discretion, with or without notice, to modify or discontinue
            the Website (or any part thereof).
          </Typography>
          <Typography variant="h2">Disclaimer of Warranties</Typography>
          <Typography>
            The Mausam website and any services included are provided on an "as
            is" basis, without any warranties of any kind, whether express or
            implied. This includes, but is not limited to, warranties of
            merchantability, fitness for a particular purpose, non-infringement,
            or absence of defects, errors, or viruses. Your use of the website
            is at your own risk.
          </Typography>
          <Typography variant="h2">Violation of Terms</Typography>
          <Typography>
            You agree that monetary damages may not be a sufficient remedy for
            any breach of this Agreement and that Mausam shall be entitled,
            without waiving any other rights or remedies, to seek injunctive or
            equitable relief as may be deemed proper by a court of competent
            jurisdiction.
          </Typography>
          <Typography variant="h2">General</Typography>
          <Typography>
            These Terms of Service constitute the entire agreement between you
            and Mausam and govern your use of the Website, superseding any prior
            agreements between you and Mausam.
          </Typography>

          <Typography>
            Effective Date:{" "}
            <b>{dayjs().startOf("month").format("DD MMMM,YYYY")}</b>
          </Typography>
        </Grid>
      </Grid>
    </>
  );
}

export default TermsOfService;
